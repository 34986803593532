import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PaymentLinkSubs.scss";
import { Input } from "components/Form";
import { BiRupee } from "react-icons/bi";
import { Select } from "components/Form";
import axios from "axios";

import { useNavigate, useNavigationType, useParams } from "react-router-dom";
import useButtonLoader from "hooks/useButtonLoader";
import { useAddons } from "context/context";
import AffiliatePayment from "./section/AffiliatePayment";
import CourseSubscriptionSkeleton from "components/skeleton/CourseSubscriptionSkeleton";
import { FaTimesCircle } from "react-icons/fa";
import { CourseBookImg } from "assets/images";
import { FcTabletAndroid } from "react-icons/fc";
import useDebounce from "hooks/useDebounce";
import UploadDocNew from "dashboard/edit-profile/UploadDocNew";

const PaymentLinkSubscription = () => {
  const { courseId, courseName } = useParams();
  const { btransactionid, admissionid } = useParams();

  console.log("btransactionid:", btransactionid);
  console.log("admissionid:", admissionid);

  const [couponToggle, setCouponToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [coupon, setCoupon] = useState("");
  const navigate = useNavigate();
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [billAmount, setBillAmount] = useState(null);
  const [courseID1, setCourseID1] = useState(null);
  const [studentID, setStudentID] = useState(null);
  const [tabData, setTabData] = useState([]);
  const [tablet, setTablet] = useState(true);

  const [buttonRef2, setButtonLoading2] = useButtonLoader("Proceed to payment");

  const [courseDetails, setCourseDetails] = useState({
    admissionCenter: "",
    conveyanceFee: 0,
    couponCode: "",
    couponCodeApplied: 0,
    courseFee: 0,
    gst: 0,
    isOldStudent: 0,
    newStudentCourseFee: 0,
    oldStudentCourseFee: 0,
    title: "",
    total: 0,
    gstFee: 0,
  });

  const [userData, setUserData] = useState({});
  const { addons } = useAddons();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Apply");

  const [isEditable, setIsEditable] = useState({
    idProof: false,
    addressProof: false,
    idphoto: false,
  });

  const [mobError, setMobError] = useState("");
  const [emobError, seteMobError] = useState("");

  const [errorField, setErrorField] = React.useState({
    firstName: "",
    lastName: "",
    Mobile: "",
    EmergencyContactNo: "",
    Education: "",
    PHouseNo: "",
    PStreet: "",
    PLandmark: "",
    PCity: "",
    PState: "",
    PZipCode: "",
    THouseNo: "",
    TStreet: "",
    TLandmark: "",
    TCity: "",
    TState: "",
    TZipCode: "",
  });

  const [isSameAddress, setIsSameAddress] = React.useState(false);

  useEffect(() => {
    if (!btransactionid || !admissionid) {
      console.error("Missing required parameters");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nodesrapi.shubhraranjan.com/api/v1/client/get-affiliate-billing-payment-details",
          {
            params: {
              btransactionid,
              admissionid,
            },
            headers: {
              API_KEY: "qaw8AxBFDXzJLbhMJyTOmkoFGLlagVUY",
            },
          }
        );

        if (response.data.code === 200) {
          const { userdetails, billingdetails } = response.data.data;

          setUserData({
            ...userdetails,
            IDProof: userdetails.IDProof || "",
            IDPhoto: userdetails.IDPhoto || "",
            addressProof: userdetails.addressProof || null,
          });
          setBillAmount(billingdetails.BillAmount);
          setStudentID(billingdetails.StudentID);
          setCourseID1(billingdetails.CourseID);

          setCourseDetails({
            title: billingdetails.CourseName,
            paymentgateway:billingdetails.paymentgateway,
            courseFee: parseFloat(billingdetails.CourseFees),
            total: parseFloat(billingdetails.BillAmount,
            ),
          });
        } else if (response.data.code === 100) {
          console.error("Invalid parameters or data");
          navigate("/payment-link-expired"); // Redirect to another page
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [btransactionid, admissionid, navigate]);



  if (loading) {
    return <CourseSubscriptionSkeleton />;
  }

  return (
    <>
      <div className="breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link>Course listing</Link>
            </li>
            <li>
              <Link>Course details</Link>
            </li>
            <li>
              <Link to="#">Payment summary</Link>
            </li>
          </ul>
        </div>
      </div>

      <section className="course_subscription spacing">
        <div className="container">
          {/* loading component */}
          {loading && <CourseSubscriptionSkeleton />}

          {/* main component */}
          {!loading && (
            <div className="subscription_content">
              <div className="personal_details">
                <h6>Subscribe</h6>
                <div className="form_group">
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="firstName"
                        value={userData.firstName || ""}
                        // onChange={onChangeHandler}
                        placeholder="Enter First Name"
                        className={`input form-control`}
                        maxLength={50}
                        label={"First Name "}
                        error={errorField.firstName || ""}
                        required
                        disabled
                      />
                    </div>

                    <div className="form_field">
                      <Input
                        type="text"
                        name="middleName"
                        placeholder="Enter Middle Name"
                        className={`input form-control `}
                        label={"Middle Name"}
                        value={userData.middleName || ""}
                        // onChange={onChangeHandler}
                        maxLength={50}
                        disabled
                      />
                    </div>

                    {userData.lastName ? (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                          className={`input form-control `}
                          label={"Last Name"}
                          value={userData.lastName || ""}
                          // onChange={onChangeHandler}
                          maxLength={50}
                          error={errorField.lastName || ""}
                          required
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                          className={`input form-control `}
                          label={"Last Name"}
                          //   onChange={onChangeHandler}
                          maxLength={50}
                          error={errorField.lastName || ""}
                          required
                          disabled
                        />
                      </div>
                    )}
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="Mobile"
                        placeholder="Enter Mobile"
                        className={`input form-control ${
                          mobError && "is-invalid"
                        }`}
                        maxLength={10}
                        value={userData.Mobile || ""}
                        //   onChange={onChangeHandler}
                        label={"Mobile Number "}
                        error={errorField.Mobile}
                        required
                        readOnly={userData.mobileVerified === true}
                        disabled
                      />
                      {mobError && (
                        <span
                          className="error"
                          style={{
                            color: "#dc3545",
                            fontSize: "13px",
                            marginLeft: "1px",
                          }}
                        >
                          {mobError}
                        </span>
                      )}
                    </div>

                    <div className="form_field">
                      <Input
                        type="text"
                        name="EmergencyContactNo"
                        placeholder="Enter Emergency Contact No"
                        className={`input form-control ${
                          emobError && "is-invalid"
                        }`}
                        value={userData.EmergencyContactNo || ""}
                        maxLength={10}
                        //   onChange={onChangeHandler}
                        label={"Emergency Number "}
                        error={errorField.EmergencyContactNo}
                        required
                        disabled
                      />
                      {emobError && (
                        <span
                          className="error"
                          style={{
                            color: "#dc3545",
                            fontSize: "13px",
                            marginLeft: "1px",
                          }}
                        >
                          {emobError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="EmailID"
                        value={userData.EmailID || ""}
                        placeholder="Enter Email Id"
                        className={`input form-control `}
                        maxLength={50}
                        label={"E-mail id "}
                        error={errorField.EmailID}
                        required
                        disabled
                      />
                    </div>

                    {userData.Education ? (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="Education"
                          value={userData.Education || ""}
                          // onChange={onChangeHandler}
                          placeholder="Enter Education"
                          className={`input form-control `}
                          maxLength={50}
                          label={"Education "}
                          error={errorField.Education}
                          required
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="Education"
                          //   onChange={onChangeHandler}
                          placeholder="Enter Education"
                          className={`input form-control `}
                          maxLength={50}
                          label={"Education "}
                          error={errorField.Education}
                          required
                          disabled
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* upload doc component */}
                <div className="dashboard edit_profile spacing">
                  <div className="edit_profile_content">
                    <div
                      className={`profile_content ${
                        !flag ? "disabled-interactions" : ""
                      }`}
                    >
                      <UploadDocNew
                        addressDoc={userData.addressProof || ""}
                        idDoc={userData.IDProof || ""}
                        idPhoto={userData.IDPhoto || ""}
                        isEditable={false}
                        flag={() => setFlag(!flag)}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <h6>Address</h6>
                <div className="form_group">
                  <b className="_title">Present address</b>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="THouseNo"
                        value={userData.THouseNo || ""}
                        placeholder="Enter House No."
                        // onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"House No. "}
                        error={errorField.THouseNo}
                        required
                        disabled
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TStreet"
                        value={userData.TStreet || ""}
                        placeholder="Enter Street Name"
                        className={`input form-control `}
                        maxLength={20}
                        // onChange={onChangeHandler}
                        label={"Street Name "}
                        error={errorField.TStreet}
                        required
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TLandmark"
                        value={userData.TLandmark || ""}
                        placeholder="Enter Landmark"
                        className={`input form-control `}
                        // onChange={onChangeHandler}
                        maxLength={30}
                        label={"Landmark "}
                        error={errorField.TLandmark}
                        required
                        disabled
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TZipCode"
                        value={userData.TZipCode || ""}
                        placeholder="Enter Zipcode"
                        className={`input form-control `}
                        maxLength={6}
                        label={"Zipcode "}
                        error={errorField.TZipCode}
                        // onChange={onChangeHandler}
                        required
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <Input
                        type="text"
                        name="TState"
                        value={userData.TState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.TState}
                        required
                      /> */}
                      <Select
                        name="TState"
                        value={userData.TState || ""}
                        //value={selectedTState}
                        // onChange={onChangeHandler}
                        className={`input form-control`}
                        label={"State "}
                        disabled
                      >
                        <option value="">Select State</option>
                        {/* {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))} */}
                      </Select>
                      {/* Error message for TState */}
                      {errorField.TState && (
                        <span className="error">{errorField.TState}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TCity"
                        value={userData.TCity || ""}
                        placeholder="Enter City"
                        className={`input form-control `}
                        maxLength={20}
                        label={"City "}
                        error={errorField.TCity}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <p className="checkbox_wrap">
                    <Input
                      type="checkbox"
                      className="input"
                      id="check"
                      checked={isSameAddress}
                      onChange={() => {
                        setIsSameAddress(!isSameAddress);
                      }}
                      // disabled={!isEditable.profile}
                    />
                    <label htmlFor="check">
                      Permanent Address same as Present Address
                    </label>
                  </p>
                  <b className="_title">Permanent address</b>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PHouseNo"
                        value={userData.PHouseNo || ""}
                        placeholder="Enter House No."
                        // onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"House No. "}
                        error={errorField.PHouseNo}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PStreet"
                        value={userData.PStreet || ""}
                        placeholder="Enter Street Name"
                        // onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"Street Name "}
                        error={errorField.PStreet}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PLandmark"
                        value={userData.PLandmark || ""}
                        placeholder="Enter Landmark"
                        className={`input form-control `}
                        // onChange={onChangeHandler}
                        maxLength={30}
                        label={"Landmark "}
                        error={errorField.PLandmark}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PZipCode"
                        value={userData.PZipCode || ""}
                        placeholder="Enter Zipcode"
                        // onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={6}
                        label={"Zipcode "}
                        error={errorField.PZipCode}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <Input
                        type="text"
                        name="PState"
                        value={userData.PState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.PState}
                        required
                        disabled={isSameAddress}
                      /> */}
                      <Select
                        name="PState"
                        //value={selectedPState}
                        value={userData.PState || ""}
                        // onChange={onChangeHandler}
                        className={`input form-control`}
                        required
                        label={"State"}
                      >
                        <option value="">Select State</option>
                        {/* {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))} */}
                      </Select>
                      {/* Error message for PState */}
                      {errorField.PState && (
                        <span className="error">{errorField.PState}</span>
                      )}
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PCity"
                        value={userData.PCity || ""}
                        // onChange={onChangeHandler}
                        placeholder="Enter City"
                        className={`input form-control `}
                        maxLength={20}
                        label={"City "}
                        error={errorField.PCity}
                        disabled={isSameAddress}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="course_detail">
                <h6>Summary</h6>
                <div className="form_group">
                  {+courseDetails?.purchased === 1 ? (
                    <>
                      <div className="already_purchased">
                        <img src={CourseBookImg} alt="" loading="lazy" />
                        <h4>You have already purchased this course.</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>{courseDetails.title}</h5>
                      <ul className="course_bill_summary">
                        {/* <li>
                  <span>Admission Center :</span>{" "}
                  <b>{courseDetails.admissionCenter}</b>
                </li> */}
                        <li>
                          <span>Course Fees :</span>
                          {courseDetails.isOldStudent === 1 ? (
                            <del>
                              <b>
                                <BiRupee /> {courseDetails.courseFee}
                              </b>
                            </del>
                          ) : (
                            <b>
                              <BiRupee /> {courseDetails.courseFee}
                            </b>
                          )}
                        </li>
                        {courseDetails.isOldStudent === 1 && (
                          <li>
                            <span>SR old student fee:</span>
                            <b>
                              <BiRupee /> {courseDetails.oldStudentCourseFee}
                            </b>
                          </li>
                        )}
                        {/* tablet price if user not purchasing tab */}
                        {courseDetails.tabCompulsory === 0 && !tablet && (
                          <li>
                            <span>Tablet Price</span>
                            <del>
                              <b>
                                <BiRupee /> {courseDetails.tabPrice}
                              </b>
                            </del>
                          </li>
                        )}

                        {/* <li>
                      <span>Conveyance fee :</span>
                      <b>
                        <BiRupee />
                        {courseDetails.conveyanceFee}
                      </b>
                    </li>
                     <li>
                  <span>GST ({courseDetails.gst}%) :</span>
                  <b>
                    <BiRupee />
                    {courseDetails.gstFee}
                  </b>
                </li> */}

                        {/* context addon display */}
                      </ul>

                      <div className="total_amount">
                        <b>Total amount</b>
                        <b>
                          <BiRupee /> {courseDetails.total}
                        </b>
                      </div>

                      {/* display all active or inactive tablet listing */}

                      {courseDetails.coursePlatform?.includes("Tablet") && (
                        <div className="tab_devices_list">
                          <ul>
                            {tabData &&
                              tabData.map((data) => {
                                return (
                                  <li
                                    className={`tab_item ${
                                      data.TabStatus === "Inactive"
                                        ? "inactive"
                                        : "active"
                                    }`}
                                    key={data.TabStudentid}
                                  >
                                    <div className="content">
                                      <FcTabletAndroid />
                                      <b>{data.TabName}</b>
                                      <span className="IMEINumber">
                                        {data.IMEINumber}
                                      </span>
                                    </div>
                                    <span
                                      className={`status ${
                                        data.TabStatus === "Inactive"
                                          ? "inactive"
                                          : "active"
                                      }`}
                                    ></span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}

                      {/* display tablet selection option if course platform is tablet and tablet ic compulsory to purchase */}
                      {+courseDetails.tabCompulsory === 0 &&
                        courseDetails.coursePlatform?.includes("Tablet") && (
                          <label className="check_box_wrap pb-0">
                            <input
                              type="checkbox"
                              checked={tablet}
                              onChange={({ target }) =>
                                setTablet(target.checked)
                              }
                            />
                            Do you want to purchase tablet ?
                          </label>
                        )}

                      {/* payment update */}
                      <AffiliatePayment
                        isEditable={isEditable}
                        // handleValidation={handleValidation}
                        studentID={studentID}
                        billAmount={billAmount}
                        userData={userData}
                        courseID1={courseID1}
                        BtransactionID={btransactionid}
                        AdmissionID={admissionid}
                        courseDetails={courseDetails}
                        buttonRef={buttonRef2}
                        setButtonLoading={setButtonLoading2}
                      />
                    </>
                  )}
                </div>

                {/* Addon courses section */}
                {/* <h6>Add-on Courses</h6>
            <div className="form_group">
              <div className="row">
                {addons && addons.length > 0 ? (
                  addons.map(({ addon_course_id, name, price }) => {
                    return (
                      <div className="col-6" key={addon_course_id}>
                        <div className="check_box_wrap p-0">
                          <input
                            type="checkbox"
                            checked={selectedAddon.some(
                              (data) => data.id === addon_course_id
                            )}
                            onChange={() =>
                              addSelectionHandler({
                                id: addon_course_id,
                                name,
                                price,
                              })
                            }
                            id={1}
                          />
                          <div
                            className="addon"
                            role="button"
                            onClick={() =>
                              addSelectionHandler({
                                id: addon_course_id,
                                name,
                                price,
                              })
                            }
                          >
                            <span className="addon-title">{name}</span>
                            <span className="addon-price">{price}/-</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12 text-center">
                      <h6>Addons Not Available</h6>
                    </div>
                  </>
                )}
              </div>
            </div> */}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default PaymentLinkSubscription;
