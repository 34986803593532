import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineSmartDisplay } from "react-icons/md";
import { FiDownload, FiUpload } from "react-icons/fi";
import { Nav, Tab } from "react-bootstrap";
import { useCourse } from "context/context";
import Pagination from "react-js-pagination";

import { toast } from "react-hot-toast";
import {
  userAttemptMainsTest,
  userTestSeries,
  userTestType,
} from "services/test";
import {
  countArray,
  momentDateFormat,
  testAccess,
  testAccessWithStartDate,
  testIsExpired,
  testIsStarted,
} from "config/utils";
import TestNotFound from "components/Not-Found/TestNotFound";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";

import { TestSeriesIcon, VideoPlayIconImg } from "assets/icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CommonModal from "components/modal-box/CommonModal";
import VideoPlayModal from "components/video-play-modal/VideoPlayModal";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import { AiOutlineDownload, AiOutlineFileText } from "react-icons/ai";
import { encryptData, errorResponse, failResponse } from "config/config";
import { vdoCipherGenerateOtp } from "services/lecture";
import useCookie from "hooks/useCookie";
import useButtonLoader from "hooks/useButtonLoader";
import VideoCipherPlayer from "components/video-player/VdoCipherPlayer";
import SuperTag from "components/SuperTag/SuperTag";
import { getUserIp } from "services/third-party";
import FileUploader from "components/FileUploader/FileUploader";
import PercentageLoader from "Loader/PercentageLoader";
import { getSignedUrl } from "services/master";

const NotAttemptedView = () => {
  const fileTypes = ["PDF"];
  const CLASS_TEST_TYPE = ["Mains", "Prelims"];

  const [allTests, setAllTests] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const [nextId, setNextId] = useState("");
  const [loading, setLoading] = useState(false);
  const [typeLoading, setTypeLoading] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState("");
  const [uploadPaperModal, setUploadPaperModal] = useState(false);

  const [fileUploadFlag, setFileUploadFlag] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Upload");

  const [videoModal, setVideoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [allNotes, setAllNotes] = useState([]);

  const [allTestTypes, setAllTestTypes] = useState([]);
  const [selectedTestType, setSelectedTestType] = useState("");

  const [selectedTestSubType, setSelectedTestSubType] = useState("Mains");
  const [answerSheet, setAnswerSheet] = useState("");

  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const [videoType, setVideoType] = useState("");

  const [cipherLoading, setCipherLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");

  const [selectedSyllabus, setSelectedSyllabus] = useState("");
  const [syllabusModal, setSyllabusModal] = useState(false);

  const [answerSubmitLoading, setAnswerSubmitLoading] = useState(false);
  const [paperSubmitLoading, setPaperSubmitLoading] = useState(false);
  const [evaluationSubmitLoading, setEvaluationSubmitLoading] = useState(false);

  const [selectedNote, setSelectedNote] = useState("");

  const { course } = useCourse();
  const { cookies } = useCookie();
  const navigate = useNavigate();

  // get test series
  const getTestSeries = async (type = "") => {
    try {
      setLoading(true);
      const data = {
        testTabType: "notattempted",
        courseIds: [course.courseId],
      };

      if (type === "next") {
        if (allTests[0]?.customId) {
          Object.assign(data, {
            nextTest: allTests[allTests.length - 1]?.customId,
          });
        } else {
          Object.assign(data, {
            nextTest: nextId,
          });
        }
      }

      if (type === "prev") {
        if (allTests[0]?.customId) {
          Object.assign(data, {
            previousTest: allTests[0]?.customId,
          });
        } else {
          Object.assign(data, {
            previousTest: nextId,
            currentTestAvailable: false,
          });
        }
      }

      const finalData = {
        ...data,
        testType: selectedTestType,
        page:activePage,
      };

      if (selectedTestType === 4) {
        Object.assign(finalData, { testSubType: selectedTestSubType });
      }

      const res = await userTestSeries(finalData);

      if (res.code === 200) {
        setAllTests(res.data.testList || []);
        setTotal(res.data.count || []);

        setNextId(res.data.nextTestId);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // this condition is used for test type condition
  const testTypeCondition = (type, subType) => {
    if (
      (+type === 3 && subType === "MCQ") ||
      (+type === 4 && subType === "Prelims")
    ) {
      return true;
    } else {
      return false;
    }
  };

  // next handler
  const nextHandler = async () => {
    getTestSeries("next");
  };

  // previous handler
  const prevHandler = async () => {
    getTestSeries("prev");
  };

  // view notes modal function
  const viewNotesModalHandler = (notes = []) => {
    setAllNotes(notes);
    setNoteModal(true);
  };

  // view videos modal function
  const viewVideosModalHandler = (videos = []) => {
    setAllVideos(videos);
    setVideoModal(true);
  };

  // generate otp of vdocipher
  const videoPlayHandler = async (file, videoTypeParam, title) => {
    setSelectedVideo(file);
    setSelectedVideoTitle(title);
    setVideoType(videoTypeParam);
    if (+videoTypeParam === 2) {
      try {
        setCipherLoading(true);
        const { data: ip } = await getUserIp();
        const res = await vdoCipherGenerateOtp({
          video_id: file,
          IPAddress: ip.ip,
        });
        if (res.code === 200) {
          setOtp(res.data.otp || "");
          setPlaybackInfo(res.data.playbackInfo || "");
          setPlayVideoModal(true);
          setCipherLoading(false);
        } else {
          failResponse(res);
          setCipherLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setCipherLoading(false);
      }
    }

    if (+videoTypeParam === 1) {
      setPlayVideoModal(true);
    }
  };

  // upload answer sheet function
  const uploadSheetHandler = async () => {
    if (!answerSheet) toast.error("Please select answer sheet");
    else {
      try {
        setSubmitLoading(true);
        setFileUploadFlag(true);
        const formData = new FormData();
        formData.append("UploadedAnswerSheet", answerSheet);
        formData.append(
          "reqData",
          encryptData({
            SectionTestID: selectedTestId,
            CourseID: course.courseId,
            access_token: cookies,
          })
        );
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setUploadPercentage(percent);
          },
          headers: {
            Authorization: "Bearer " + cookies,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };

        const res = await userAttemptMainsTest(formData, options);

        if (res.code === 200) {
          setSubmitLoading(false);
          setUploadPaperModal(false);
          setFileUploadFlag(false);
          toast.success(res.message);
        } else {
          failResponse(res);
          setSubmitLoading(false);
          setFileUploadFlag(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
        setFileUploadFlag(false);
      }
    }
  };

  // navigation handler
  const navigationHandler = (id, startDate, endDate) => {
    if (testIsStarted(startDate)) {
      toast.error("Test is not started");
      return;
    }
    if (testIsExpired(endDate)) {
      toast.error("Test is expired");
      return;
    }
    if (testAccess(startDate, endDate)) {
      navigate(`detail/${id}`, {
        state: { auth: true },
      });
    }
  };

  // result handler
  const resultHandler = (id, startDate, endDate, result) => {
    if (testIsStarted(startDate)) {
      toast.error("Test is not started");
      return;
    }
    if (testIsExpired(endDate)) {
      toast.error("Test is expired");
      return;
    }
    if (testAccess(startDate, endDate)) {
      if (result) {
        navigate(`/dashboard/test-series/detail/${id}/test-overview`, {
          state: { auth: true },
        });
      } else {
        toast.error("result is not generated");
      }
    }
  };

  // notes download handler
  const notesDownloadHandler = (notes, startDate, endDate) => {
    viewNotesModalHandler(notes);
  };

  // videos download handler
  const viewVideosHandler = (videos, startDate) => {
    if (testAccessWithStartDate(startDate)) {
      viewVideosModalHandler(videos);
    } else {
      toast.error("you can't play videos");
    }
  };

  // syllabus download handler
  const syllabusDownloadHandler = (syllabus) => {
    setSelectedSyllabus(syllabus);
    setSyllabusModal(true);
  };

  // answer sheet download
  const modalAnswerSheetDownloadHandler = async (sheet, startDate) => {
    if (testAccessWithStartDate(startDate)) {
      setAnswerSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: sheet }));
      setAnswerSubmitLoading(false);
    } else {
      toast.error("you can't download model answer sheet");
    }
  };
  // test pdf download handler
  const paperPDFDownloadHandler = async (paper, startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      setPaperSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: paper }));
      setPaperSubmitLoading(false);
    } else {
      toast.error("you can't download question sheet");
    }
  };

  // evaluation pdf download handler
  const evaluationSheetDownloadHandler = async (paper) => {
    setEvaluationSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: paper }));
    setEvaluationSubmitLoading(false);
  };

  // upload answer handler
  const uploadAnswerHandler = (id, startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      setUploadPaperModal(true);
      setSelectedTestId(id);
    } else {
      toast.error("you can't upload answer sheet");
    }
  };

  // notes download handler
  const singleNotesDownloadHandler = async (note) => {
    setSelectedNote(note);
    window.open(await getSignedUrl({ pdfUrl: note }));
    setSelectedNote("");
  };

  // ----------------------- getting test types -------------------------//
  // React.useLayoutEffect(() => {
  //   // get test types
  //   const getTestTypes = async () => {
  //     try {
  //       setTypeLoading(true);
  //       const res = await userTestType({ courseIds: [course.courseId] });

  //       if (res.code === 200) {
  //         setAllTestTypes(res.data || []);
  //         setSelectedTestType(res.data[0]?.TestTypeID || "");
  //         setTypeLoading(false);
  //       } else {
  //         failResponse(res);
  //         setTypeLoading(false);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //       setTypeLoading(false);
  //     }
  //   };

  //   if (course.courseId) {
  //     setSelectedTestType("");
  //     getTestTypes();
  //   }
  //   // eslint-disable-next-line
  // }, [course]);
  React.useLayoutEffect(() => {
    // Function to get test types
    const getTestTypes = async () => {
      try {
        setTypeLoading(true);
        const res = await userTestType({ courseIds: [course.courseId] });
  
        setTypeLoading(false);
        if (res.code === 200) {
          setAllTestTypes(res.data || []);
          const firstTestTypeId = res.data[0]?.TestTypeID || "";
          setSelectedTestType(firstTestTypeId);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
  
    // Only fetch test types if courseId changes
    if (course.courseId) {
      setSelectedTestType(""); // Reset selectedTestType
      setActivePage(1); // Reset activePage
      getTestTypes(); // Fetch test types
    }
  
    // eslint-disable-next-line
  }, [course]);

  // --------------- getting all test series of list view data ----------------//
  React.useEffect(() => {
    // Reset activePage to 1 whenever selectedTestSubType or selectedTestType changes
    setActivePage(1);
  }, [selectedTestSubType, selectedTestType]);

  // --------------- Getting all test series of list view data ----------------//
React.useLayoutEffect(() => {
  // Calls getTestSeries if courseId and selectedTestType are set
  if (course.courseId && selectedTestType) {
    getTestSeries();
  }

  // eslint-disable-next-line
}, [selectedTestType, selectedTestSubType, activePage]);

  return (
    <>
      <div>
        <Tab.Container id="SubjectList" className="" defaultActiveKey="Main">
          <div className="dashboard_tab subject_list">
            {/* test type list */}
            <Nav variant="pills">
              {/* loading component */}
              {typeLoading && (
                <>
                  {countArray(8).map((data) => {
                    return (
                      <Nav.Item key={data} className="w-25">
                        <Nav.Link className="p-0">
                          <ListCourseSkeleton height={40} />
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </>
              )}

              {/* main component */}
              {/* TypeName fetch */}

              {!typeLoading && allTestTypes?.length > 0 && (
                <>
                  {allTestTypes &&
                    allTestTypes.map(({ TestTypeName, TestTypeID }, i) => {
                      return (
                        <Nav.Item
                          onClick={() => setSelectedTestType(TestTypeID)}
                          key={i}
                        >
                          <Nav.Link
                            className={
                              selectedTestType === TestTypeID && "active"
                            }
                          >
                            {TestTypeName}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </>
              )}

              {/* not found component */}
              {!typeLoading && allTestTypes?.length <= 0 && (
                <TestNotFound title="Test series" />
              )}
            </Nav>

                {/* ClassTesttype fetch */}

            {selectedTestType === 4 && (
              <Nav variant="pills">
                {CLASS_TEST_TYPE &&
                  CLASS_TEST_TYPE.map((data, i) => {
                    return (
                      <Nav.Item
                        onClick={() => setSelectedTestSubType(data)}
                        key={i}
                      >
                        <Nav.Link
                          className={selectedTestSubType === data && "active"}
                        >
                          {data}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
              </Nav>
            )}

            {selectedTestType && (
              <Tab.Content>
                <Tab.Pane eventKey="Main">
                <div className="pagination_search_class">
                    <div className="d-flex flex-column flex-md-row justify-content-between py-2 align-items-center">
                      {/* Pagination on the top for mobile and left side for desktop */}
                      <div className="d-flex mb-2 mb-md-0">
                        {total > 10 ? (
                          <div className="pagination_wrap">
                            <Pagination
                              activePage={activePage}
                              previousLabel={"previous"}
                              nextLabel={"next"}
                              itemsCountPerPage={10}
                              totalItemsCount={total}
                              pageRangeDisplayed={5}
                              activeLinkClass={"active"}
                              onChange={(page) => setActivePage(page)}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="meeting_content">
                    
                    <ul>
                      <li className="lecture-card test-card heading">
                        <div className="content">
                          <span className="img"></span>
                          <h6>Test Name</h6>
                          <div className="date">
                            <b>Start Date</b>
                          </div>
                          <div className="date">
                            <b>End Date</b>
                          </div>
                          <div className="date">
                            <b>Marks</b>
                          </div>
                          <div className="date">
                            <b>Duration</b>
                          </div>
                        </div>
                      </li>
                      {/* loading comp */}
                      {loading && (
                        <>
                          {countArray(3).map((data) => {
                            return (
                              <li key={data} className="p-0">
                                <ListCourseSkeleton height="90" />
                              </li>
                            );
                          })}
                        </>
                      )}

                      {/* not found comp */}
                      {!loading && allTests?.length <= 0 && (
                        <TestNotFound title="Tests" />
                      )}

                      {/* main component */}
                      {!loading &&
                        allTests.length > 0 &&
                        allTests.map(
                          (
                            {
                              testDetails,
                              scheduleDate,
                              ValidDate,
                              TestID,
                              mcqResult,
                              videoAccessDate,
                              subjectiveResult,
                            },
                            i
                          ) => {
                            return (
                              <li
                                key={i}
                                className={`test-card ${
                                  !testAccess(scheduleDate, ValidDate) &&
                                  "disabled"
                                }`}
                              >
                                <div className="content">
                                  <Link
                                    // to={`detail/${TestID}`}
                                    className="image"
                                  >
                                    <img
                                      src={TestSeriesIcon}
                                      alt=""
                                      loading="lazy"
                                    />
                                  </Link>
                                  <h6>{testDetails.TestTitle}</h6>
                                  <div className="date">
                                    <b>{momentDateFormat(scheduleDate)}</b>
                                  </div>
                                  <div className="date red">
                                    <b>{momentDateFormat(ValidDate)}</b>
                                  </div>
                                  <div className="date">
                                    <b>{testDetails.TotalMarks}</b>
                                  </div>
                                  <div className="date">
                                    <b>{testDetails.TestDuration} mins</b>
                                  </div>
                                </div>

                                {/* Prelims test buttons */}
                                {testTypeCondition(
                                  testDetails.TestType,
                                  testDetails.TestSubType
                                ) && (
                                  <div className="button_wrap">
                                    <button
                                      className="dashboard_button red"
                                      onClick={() =>
                                        navigationHandler(
                                          TestID,
                                          scheduleDate,
                                          ValidDate
                                        )
                                      }
                                      disabled={
                                        !testAccess(scheduleDate, ValidDate)
                                      }
                                    >
                                      {`${mcqResult ? "Retake" : "Take"} test`}
                                    </button>
                                    {/* // prelims result button */}
                                    <button
                                      type="button"
                                      className="dashboard_button blue"
                                      onClick={() =>
                                        resultHandler(
                                          TestID,
                                          scheduleDate,
                                          ValidDate,
                                          mcqResult
                                        )
                                      }
                                      disabled={
                                        !testAccess(scheduleDate, ValidDate) ||
                                        !mcqResult
                                      }
                                    >
                                      View result
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={testDetails.notes.length <= 0}
                                      onClick={() =>
                                        notesDownloadHandler(
                                          testDetails.notes,
                                          scheduleDate,
                                          ValidDate
                                        )
                                      }
                                    >
                                      <FiDownload /> Notes
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={!testDetails.SyllabusPDF}
                                      onClick={() =>
                                        syllabusDownloadHandler(
                                          testDetails.SyllabusPDF,
                                          scheduleDate,
                                          ValidDate
                                        )
                                      }
                                    >
                                      <AiOutlineFileText /> Syllabus
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={
                                        !testAccessWithStartDate(
                                          videoAccessDate
                                        ) || testDetails.videos.length <= 0
                                      }
                                      onClick={() => {
                                        viewVideosHandler(
                                          testDetails.videos,
                                          videoAccessDate
                                        );
                                      }}
                                    >
                                      <MdOutlineSmartDisplay /> Video
                                    </button>
                                  </div>
                                )}

                                {/* main test buttons */}
                                {!testTypeCondition(
                                  testDetails.TestType,
                                  testDetails.TestSubType
                                ) && (
                                  <div className="button_wrap test_button">
                                    <div className="w-100 d-flex mb-3">
                                      <button
                                        to="test"
                                        type="button"
                                        className="dashboard_button red fill"
                                        onClick={() =>
                                          paperPDFDownloadHandler(
                                            testDetails.TestPaperPDF,
                                            scheduleDate,
                                            ValidDate
                                          )
                                        }
                                        disabled={
                                          !testAccess(
                                            scheduleDate,
                                            ValidDate
                                          ) ||
                                          !testDetails.TestPaperPDF ||
                                          paperSubmitLoading
                                        }
                                      >
                                        <FiDownload />
                                        <div>Question paper</div>
                                      </button>

                                      <button
                                        type="button"
                                        className="dashboard_button blue fill"
                                        onClick={() => {
                                          uploadAnswerHandler(
                                            TestID,
                                            scheduleDate,
                                            ValidDate
                                          );
                                        }}
                                        disabled={
                                          !testAccess(
                                            scheduleDate,
                                            ValidDate
                                          ) || !testDetails.TestPaperPDF
                                        }
                                      >
                                        <FiUpload /> <div>Upload paper</div>
                                      </button>
                                      <button
                                        type="button"
                                        className="dashboard_button blue"
                                        onClick={() =>
                                          evaluationSheetDownloadHandler(
                                            subjectiveResult?.EvaluatedAnswerSheet
                                          )
                                        }
                                        disabled={
                                          (subjectiveResult?.EvaluationStatus !==
                                            "Evaluated" &&
                                            !subjectiveResult?.EvaluatedAnswerSheet) ||
                                          evaluationSubmitLoading
                                        }
                                      >
                                        <FiDownload />{" "}
                                        <div>Evaluated Sheet</div>
                                      </button>
                                    </div>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      onClick={() =>
                                        modalAnswerSheetDownloadHandler(
                                          testDetails.ModelAnswerSheetPDF,
                                          scheduleDate
                                        )
                                      }
                                      disabled={
                                        !testAccessWithStartDate(
                                          scheduleDate
                                        ) ||
                                        !testDetails.ModelAnswerSheetPDF ||
                                        answerSubmitLoading
                                      }
                                    >
                                      <FiDownload /> <div>Model Answer</div>
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      onClick={() =>
                                        modalAnswerSheetDownloadHandler(
                                          testDetails.subjectiveResult
                                            ?.UploadedAnswerSheet,
                                          scheduleDate
                                        )
                                      }
                                      disabled={
                                        !testAccessWithStartDate(
                                          scheduleDate
                                        ) ||
                                        !testDetails.subjectiveResult
                                          ?.UploadedAnswerSheet ||
                                        answerSubmitLoading
                                      }
                                    >
                                      <FiDownload /> <div>Answer Sheet</div>
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={testDetails.notes?.length <= 0}
                                      onClick={() =>
                                        notesDownloadHandler(
                                          testDetails.notes,
                                          scheduleDate,
                                          ValidDate
                                        )
                                      }
                                    >
                                      <FiDownload /> <div>Notes</div>
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={!testDetails.SyllabusPDF}
                                      onClick={() =>
                                        syllabusDownloadHandler(
                                          testDetails.SyllabusPDF,
                                          scheduleDate,
                                          ValidDate
                                        )
                                      }
                                    >
                                      <AiOutlineFileText /> <div>Syllabus</div>
                                    </button>
                                    <button
                                      type="button"
                                      className="dashboard_button"
                                      disabled={
                                        !testAccess(scheduleDate, ValidDate) ||
                                        testDetails.videos.length <= 0
                                      }
                                      onClick={() => {
                                        viewVideosHandler(
                                          testDetails.videos,
                                          scheduleDate,
                                          ValidDate
                                        );
                                      }}
                                    >
                                      <MdOutlineSmartDisplay />
                                      <div>Play video</div>
                                    </button>
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            )}
          </div>
        </Tab.Container>
      </div>

      {/* Video modal */}
      <CommonModal
        show={videoModal}
        handleClose={() => setVideoModal(false)}
        className={"modal-lg"}
        title={"Videos Notes"}
      >
        <div className="course_listing">
          <ul>
            {allVideos &&
              allVideos.map(({ videoDeatils }, i) => {
                return (
                  <li key={i}>
                    <span className="sr_number">{++i}.</span>
                    <p>
                      {videoDeatils?.Videotitle} ({videoDeatils?.targetyear})
                    </p>

                    {/* video play button */}
                    {videoDeatils?.Videonumber === selectedVideo ? (
                      cipherLoading ? (
                        <button type="button" className="button">
                          <div
                            class="spinner-border spinner-border-sm text-light"
                            role="status"
                          >
                            <span class="sr-only"></span>
                          </div>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="button"
                          onClick={() => {
                            videoPlayHandler(
                              videoDeatils?.Videonumber,
                              videoDeatils?.type,
                              `${videoDeatils?.Videotitle} (${videoDeatils?.targetyear})`
                            );
                          }}
                          disabled={!videoDeatils?.Videonumber}
                        >
                          <img src={VideoPlayIconImg} alt="" loading="lazy" />
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="button"
                        onClick={() => {
                          videoPlayHandler(
                            videoDeatils?.Videonumber,
                            videoDeatils?.type,
                            `${videoDeatils?.Videotitle} (${videoDeatils?.targetyear})`
                          );
                        }}
                        disabled={!videoDeatils?.Videonumber}
                      >
                        <img src={VideoPlayIconImg} alt="" loading="lazy" />
                      </button>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </CommonModal>

      {/* Note modal */}
      <CommonModal
        show={noteModal}
        className={"modal-lg"}
        handleClose={() => setNoteModal(false)}
        title={"Notes"}
      >
        <div className="course_listing">
          <ul>
            {allNotes &&
              allNotes.map(({ noteDeatils }, i) => {
                return (
                  <li key={i}>
                    <span className="sr_number">{++i}.</span>
                    <p>
                      {noteDeatils?.Vnotetitle} ({noteDeatils?.Vtargetyear})
                    </p>
                    <button
                      type="button"
                      className="button"
                      onClick={() =>
                        singleNotesDownloadHandler(noteDeatils?.VnoteFile)
                      }
                      disabled={
                        !noteDeatils?.VnoteFile ||
                        selectedNote === noteDeatils?.VnoteFile
                      }
                    >
                      <AiOutlineDownload />
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
      </CommonModal>

      {/* Youtube Video Player Modal */}
      <VideoPlayModal
        modalShow={playVideoModal}
        modalHide={() => setPlayVideoModal(false)}
      >
        <h5 className="text-center text-white my-2 mb-0">
          {selectedVideoTitle}
        </h5>
        {/* Youtube Player */}
        {+videoType === 1 && selectedVideo && (
          <>
            <YoutubeVideo src={selectedVideo} width="100%" height="400" />
          </>
        )}

        {/* Vdocipher Player */}
        {+videoType === 2 && selectedVideo && (
          <>
            {playbackInfo && otp && (
              <VideoCipherPlayer otp={otp} playbackInfo={playbackInfo} />
            )}
          </>
        )}
      </VideoPlayModal>

      {/* upload paper modal */}
      <CommonModal
        show={uploadPaperModal}
        className={"modal-lg reupload_loader"}
        handleClose={() => {
          if (!fileUploadFlag) {
            setUploadPaperModal(false);
            setAnswerSheet("");
          }
        }}
        title={"Upload Answer Sheet"}
      >
        <div className="testPage_upload_paper">
          <FileUploader
            onChange={(file) => setAnswerSheet(file)}
            file={answerSheet}
            types={fileTypes}
            classes={`px-3 h-50 test_upload file_uploader ${
              fileUploadFlag && "disabled"
            }`}
            message={"File selected,click on upload to save it"}
          />
          {fileUploadFlag && <PercentageLoader percentage={uploadPercentage} />}
          <div className="upload_paper__footer text-center my-3">
            <button
              className="button"
              onClick={uploadSheetHandler}
              ref={submitButtonRef}
            >
              Upload
            </button>
          </div>
        </div>
      </CommonModal>

      {/* syllabus modal */}
      <CommonModal
        show={syllabusModal}
        handleClose={() => setSyllabusModal(false)}
        size="lg"
        title={"Syllabus"}
      >
        <div className="terms_condition">
          <div className="terms_condition_content">
            <SuperTag>{selectedSyllabus}</SuperTag>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default NotAttemptedView;
