import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Userimg } from "assets/icons";
import OtpInput from "react-otp-input";
import { BsArrowRight } from "react-icons/bs";
import useButtonLoader from "hooks/useButtonLoader";
import useCookie from "hooks/useCookie";
import { userSendOTP, userVerifyOTP } from "services/auth";
import { toast } from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { getBrowserName, getOSDetail } from "config/utils";
import { getUserIp } from "services/third-party";
import { useCourse } from "context/context";
import { errorResponse, failResponse } from "config/config";
import { fetchToken } from "config/firebase";

const OTPVerify = ({ setEnterOtp, userId, userName }) => {
  const [time, setTime] = useState(15);

  const [mobileOTP, setMobileOTP] = useState("");

  const [mobileLoading, setMobileLoading] = useState(false);
  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Continue", true);
  const { setCookie } = useCookie();
  const navigate = useNavigate();
  const location = useLocation();
  const { setCourse } = useCourse();

  // mobile otp handler
  const resendMobileOTPHandler = async () => {
    setMobileOTP("");
    const data = {
      mobile: userName,
      otpType: "mobile",
      type: "login",
    };
    setMobileLoading(true);
    try {
      const res = await userSendOTP(data);

      if (res.code === 200) {
        toast.success(res.message);
        setMobileLoading(false);
        setTime(15);
      } else {
        failResponse(res);
        setMobileLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setMobileLoading(false);
    }
  };

  // verify otp handler
  const verifyOTpHandler = async () => {
    if (mobileOTP.length !== 4) {
      toast.error("Please Enter OTP");
    } else {
      setSubmitLoading(true);
      try {
        // generating firebase push notification token
        const fcmToken = await fetchToken();

        const { data: ip } = await getUserIp();

        const res = await userVerifyOTP({
          type: "login",
          device: "Web",
          platformType: getBrowserName(),
          os: getOSDetail(),
          IPaddress: ip.ip,
          userType: "login",
          otpType: "mobile",
          mobile: userName,
          UserID: userId,
          mobileOTP,
          fcmToken,
        });

        if (res.code === 200) {
          setSubmitLoading(false);
          setEnterOtp(true);
          setCookie(res.data.access_token);
          toast.success("Login Successfully");
          sessionStorage.removeItem("course");
          setCourse({});
          // checks previous path is summary or other
          if (location.state?.id && !location.state?.freeCourse) {
            if (location.state?.centerid) {
              navigate(
                `/course-subscription/${location.state?.name}/${location.state?.id}/${location.state?.centerid}`
              );
            }
            else {
              navigate(
                `/course-subscription/${location.state?.name}/${location.state?.id}`
              );
            }



          } else {
            navigate("/dashboard/home");
          }
        } else {
          failResponse(res);
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    }
  };

  // otp timer function
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  // alert before refresh
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <ul className="user_otp">
        <li className="user_image">
          <img src={Userimg} alt="" loading="lazy" />
        </li>
        <li>
          <p>OTP sent to {userName.slice(0, 2)}****</p>
          <p>
            Not you?{" "}
            <Link to="" onClick={() => setEnterOtp(false)}>
              Phone Number
            </Link>
          </p>
        </li>
      </ul>
      <div className="enter_otp">
        <p className="text">Enter 4 digit OTP</p>
        <OtpInput
          numInputs={4}
          inputType="number"
          className="optINput"
          placeholder="0000"
          value={mobileOTP}
          onChange={(data) => setMobileOTP(data.replace(/\D/g, ""))}
        />
        <p className="resend_button">
          {time === 0 &&
            (mobileLoading ? (
              <Spinner animation="border" variant="danger" size="sm" />
            ) : (
              <Link to="" onClick={resendMobileOTPHandler}>
                Resend OTP
              </Link>
            ))}
          {time !== 0 && <span>{time} secs</span>}
        </p>
      </div>
      <div className="button_wrap">
        <button
          onClick={verifyOTpHandler}
          className="button"
          disabled={mobileOTP.length !== 4}
          ref={submitButtonRef}
        >
          Continue
          <span className="icon">
            <BsArrowRight />
          </span>
        </button>
      </div>
    </>
  );
};

export default React.memo(OTPVerify);
