import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { GoogleIcon } from "assets/icons";
import { Courseimg1, Forming, SignUp11, SignUp22 } from "assets/images";
import { useGoogleLogin } from "@react-oauth/google";
import "./Form.scss";
import OTPVerify from "./Section/Login/OTPVerify";
import Success from "./Section/Login/Success";
import { Input } from "components/Form";
import { toast } from "react-hot-toast";
import useButtonLoader from "hooks/useButtonLoader";
import {
  getBrowserName,
  getOSDetail,
  isLoggedIn,
  validateEmail,
  validateMobile,
} from "config/utils";
import usePasswordToggle from "hooks/usePasswordToggle";
import { userLogin, userSendOTP } from "services/auth";
import useCookie from "hooks/useCookie";
import axios from "axios";
import { getUserIp } from "services/third-party";
import { useCourse } from "context/context";
import { errorResponse, failResponse } from "config/config";
import { fetchToken } from "config/firebase";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [enterOTP, setEnterOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorField, setErrorField] = useState("");
  const [errorField2, setErrorField2] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Continue", true);
  const [type, icon] = usePasswordToggle();
  const { setCookie } = useCookie();
  const navigate = useNavigate();
  const { setCourse } = useCourse();
  const location = useLocation();

  // const [loginData, setLoginData] = useState({
  //   email: "",
  //   mobile: "",
  //   password: "",
  //   loginType: "",
  //   mobileOTP: "",
  // });

  // /social login handler
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );

        // generating firebase push notification token
        const fcmToken = await fetchToken();

        const { data: ip } = await getUserIp();
        const res = await userLogin({
          device: "Web",
          platformType: getBrowserName(),
          os: getOSDetail(),
          IPaddress: ip.ip,
          userType: "login",
          socialId: data?.id,
          loginType: "social",
          fcmToken,
        });

        if (res.code === 200) {
          toast.success("Login Successfully");
          setCookie(res.data.access_token);
          setCourse({});
          //navigate("/dashboard/home");
          const supcoursename = localStorage.getItem("supcoursename");
          const supcourseid = localStorage.getItem("supcourseid");
          const centerid = localStorage.getItem("centerid");
          const utoken = localStorage.getItem("utoken");

          if (supcoursename !== "supcoursenameremove" && supcourseid !== "supcourseidremove") {

            const remcname = supcoursename;
            const remcid = supcourseid;
            localStorage.setItem("supcoursename", "supcoursenameremove");
            localStorage.setItem("supcourseid", "supcourseidremove");
            if (centerid === "undefined") {
              navigate(
                `/course-subscription/${remcname}/${remcid}`, {
              }
              );
            } else {
              navigate( 
                `/course-subscription/${remcname}/${remcid}/${centerid}`, {
              }
              );
            }
            localStorage.removeItem("supcoursename");
            localStorage.removeItem("supcourseid");

          }
          else if (utoken == "utoken") {
            localStorage.setItem("utoken", "utokendone");
            navigate("/my-token");
          }
          else {
            navigate("/dashboard/home");
          }


        } else {
          failResponse(res);
          const supcoursename = localStorage.getItem("supcoursename");
          const supcourseid = localStorage.getItem("supcourseid");

          const remcname = supcoursename;
          const remcid = supcourseid;
          navigate("/signup", { state: { remcname, remcid }, });
        }
      } catch (err) {
        toast.error("Login Issue");
      }
    },
  });

  const onChangeHandler = (e) => {
    setUserName(e.target.value.replace(/\D/g, ""));
  };

  // email and number verification
  const userNameVerification = () => {
    // check email format
    if (validateEmail(userName)) {
      setIsEmail(true);
      setIsMobile(false);
      return { email: true, mobile: false };
    }

    // check mobile format
    if (validateMobile(userName)) {
      setIsMobile(true);
      setIsEmail(false);
      return { email: false, mobile: true };
    }
  };

  // login Handler
  const loginHandler = async (e) => {
    e.preventDefault();
    if (!userName) {
      setErrorField("Please enter phone number");
    } else {
      // const res = userNameVerification();
      // let data = {};
      // if (res?.email) {
      //   data.email = userName;
      // }
      // if (res?.mobile) {
      //   data.mobile = userName;
      // }
      //
      // if (!data.email && !data.mobile) {
      //   toast.error("Please enter a valid email/phone number");
      // }

      // email with password process
      // if (res?.email) {
      //   if (!password) {
      //     setErrorField2("Please enter password");
      //   } else {
      //     setSubmitLoading(true);
      //     try {
      //       const res = await userLogin({
      //         email: userName,
      //         loginType: "email",
      //         password: password,
      //       });

      //       if (res.code === 200) {
      //         toast.success("Login Successfully");
      //         setCookie(res.data.access_token);
      //         setOtpVerified(true);
      //         setSubmitLoading(false);
      //         // checks previous path is summary or other
      //         if (location.state?.id) {
      //           navigate(`/course-subscription/${location.state?.id}`);
      //         } else {
      //           navigate("/dashboard/home");
      //         }
      //       } else {
      //         failResponse(res);
      //         setSubmitLoading(false);
      //       }
      //     } catch (err) {
      //       toast.error(err?.response?.data?.message || err.message);
      //       setSubmitLoading(false);
      //     }
      //   }
      // }

      // mobile with otp process
      // if (res?.mobile) {
      setSubmitLoading(true);
      try {
        const { data: ip } = await getUserIp();
        const res = await userSendOTP({
          device: "Web",
          platformType: getBrowserName(),
          os: getOSDetail(),
          IPaddress: ip.ip,
          userType: "login",
          otpType: "mobile",
          type: "login",
          mobile: userName,
        });

        if (res.code === 200) {
          toast.success("OTP Sent Successfully");
          setSubmitLoading(false);
          setUserId(res.data.UserID);
          setEnterOtp(true);
        } else {
          failResponse(res);
          //navigate("/signup");
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
      // }
    }
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: "linear",
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="login spacing">
      <div className="form_container">
        <div className="content_section">
          <div className="content">
            <Slider {...settings}>
              <div className="item">
                <div className="item_content">
                  <div className="image_wrap">
                    <img src={SignUp11} alt="students" loading="lazy" />
                  </div>
                  <div className="text">
                    {/* <p>
                      Achieve your goal to become a civil servant with the
                      nation’s leading institute for UPSC training
                    </p> */}
                    <p>
                      If you want to be an
                      IAS, IFS, IPS, IRS
                      officer like them then join us …
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="item_content">
                  <div className="image_wrap">
                    <img src={SignUp22} alt="students" loading="lazy" />
                  </div>
                  <div className="text">
                    <p>
                      If you want to be an
                      IAS, IFS, IPS, IRS
                      officer like them then join us …
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className="form_section">
            {otpVerified === true ? (
              <Success />
            ) : (
              <>
                <div className="form_title">
                  <h3>Sign in</h3>
                  <p>To view courses & your dashboard</p>
                </div>
                <Link to="" onClick={() => login()} className="logwith">
                  <img src={GoogleIcon} alt="google plus" loading="lazy" />
                  Continue with Google
                </Link>
                <center><span>OR Login with Mobile</span></center><br />
                {/* <Link to="" onClick={() => login()} className="logwith">
                  <img src={GoogleIcon} alt="google plus" loading="lazy" />
                  Login with Google
                </Link> */}
                {/* OTP Verify Screen */}
                {enterOTP && (
                  <OTPVerify
                    setEnterOtp={setEnterOtp}
                    userName={userName}
                    userId={userId}
                  />
                )}

                <form className="form" onSubmit={loginHandler}>
                  {/* Login Screen */}
                  {!enterOTP && (
                    <>
                      <div className="form_field">
                        <Input
                          type="text"
                          name="userName"
                          value={userName || ""}
                          onChange={onChangeHandler}
                          placeholder="Enter your registered mobile number"
                          className={`input form-control `}
                          maxLength={10}
                          label={"Mobile Number"}
                          error={errorField}
                          required
                        />
                      </div>

                      {/* if user enter email password field will display*/}
                      {isEmail && (
                        <div className="form_field has_icon">
                          <Input
                            type={type}
                            name="password"
                            value={password || ""}
                            onChange={({ target }) => setPassword(target.value)}
                            placeholder="Enter password"
                            className={`input form-control d-inline `}
                            maxLength={50}
                            label={"Password"}
                            error={errorField2}
                            required
                          />
                          <span className="eye_icon open">{icon}</span>
                        </div>
                      )}
                      {/* <p className="forget-password_text">
                        <Link to="/forgot-password">Forgot password</Link>
                      </p> */}

                      <div className="button_wrap">
                        <Link
                          to=""
                          ref={submitButtonRef}
                          onClick={loginHandler}
                          className="button"
                          type="submit"
                        >
                          Continue
                          <span className="icon">
                            <BsArrowRight />
                          </span>
                        </Link>
                      </div>
                    </>
                  )}
                </form>
                {/* <p className="divider">OR</p> */}


                <p className="signup_text">
                  <span>Not registered yet ?</span>{" "}
                  <Link to="/signup">Sign up</Link>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
