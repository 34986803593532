import PercentageLoader from "Loader/PercentageLoader";
import axios from "axios";
import useCookie from "hooks/useCookie";
import React, { useState, useRef } from "react";

import { AiOutlineInfoCircle, AiOutlineUpload } from "react-icons/ai";
import {
  decryptData,
  encryptData,
  errorResponse,
  failResponse,
} from "config/config";
import FileUploader from "components/FileUploader/FileUploader";
import { getBrowserName } from "config/utils";
const fileTypes = ["JPEG", "jpg", "PNG", "GIF"];

const UploadDocNew = ({ isEditable, addressDoc, idDoc, flag, idPhoto, onUploadComplete, disabled }) => {
  const [flagId, setFlagId] = useState(false);
  const [flagphotoId, setphotoFlagId] = useState(false);
  const [flagAddress, setFlagAddress] = useState(false);

  const [addressProof, setAddressProof] = useState("");
  const [IDPhoto, setidphoto] = useState("");
  const [idProof, setIdProof] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { cookies } = useCookie();

  const idInputRef = useRef(null);
  const addressInputRef = useRef(null);
  const idphotoInputRef = useRef(null);

  const handleIconClick = (ref) => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  const handleFileChange = (type, event) => {
    const file = event.target.files[0];
    ImageUploadHandler(type, file);
  };

  const ImageUploadHandler = async (type, file) => {
    if (!file) return;

    if (type === "idProof") {
      setIdProof(file);
      setFlagId(true);
    } else if (type === "addressProof") {
      setAddressProof(file);
      setFlagAddress(true);
    } else if (type === "profileImage") {
      setidphoto(file);
      setphotoFlagId(true); // Adjust this as needed for profile image flag
    }

    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append(
        "reqData",
        encryptData({
          docType: type,
          platformType: getBrowserName(),
          access_token: cookies,
        })
      );

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setUploadPercentage(percent);
        },
      };

      const res = decryptData(
        await axios.post(
          `${process.env.REACT_APP_API_URL}/user/upload-user-document`,
          formData,
          options
        )
      );

      if (res.code === 200) {
        if (type === "idProof") setFlagId(false);
        else if (type === "addressProof") setFlagAddress(false);
        else if (type === "profileImage") setphotoFlagId(false); // Adjust this as needed
        flag();
      } else {
        failResponse(res);
        resetFlags(type);
      }
      if (onUploadComplete) {
        onUploadComplete();
      }
    } catch (err) {
      errorResponse(err);
      resetFlags(type);
    }
  };

  const resetFlags = (type) => {
    if (type === "idProof") {
      setIdProof(null);
      setFlagId(false);
    } else if (type === "addressProof") {
      setAddressProof(null);
      setFlagAddress(false);
    } else if (type === "profileImage") {
      setidphoto(null);
      setphotoFlagId(false); // Adjust this as needed
    }
  };

  const uploadDocumentStyle = {
    width: '49%',
    ...(window.innerWidth <= 768 && { width: '100%' })
  };

  React.useEffect(() => {
    setIdProof(idDoc || null);
    setAddressProof(addressDoc || null);
    setidphoto(idPhoto || null);
  }, [idDoc, addressDoc, idPhoto]);

  return (
    <div className={`upload_document ${disabled ? "disabled" : ""}`}>
      <div className="id_document">
        <h6>
          ID Proof Documents <AiOutlineInfoCircle />
        </h6>
        <div className="content">
          <FileUploader
            onChange={(file) => ImageUploadHandler("idProof", file)}
            file={idProof}
            classes="file_uploader"
            types={fileTypes}
            disabled={disabled || idProof === ""}
          />

          {idProof && (
            <div className="document_image">
              <img
                src={!idProof?.name ? idProof : URL.createObjectURL(idProof)}
                alt=""
                style={{ pointerEvents: disabled ? "none" : "auto" }}
              />
            </div>
          )}
          {flagId && <PercentageLoader percentage={uploadPercentage} />}
        </div>
        {idProof && (
          <p>
            <input
              type="file"
              style={{ display: "none" }}
              ref={idInputRef}
              onChange={(e) => handleFileChange("idProof", e)}
              accept={fileTypes.map((type) => `.${type}`).join(", ")}
              disabled={disabled}
            />
            <AiOutlineUpload
              style={{ cursor: disabled ? "not-allowed" : "pointer", marginLeft: "10px", fontSize: "24px", fontWeight: "bold", strokeWidth: "50", color: "rgb(73, 113, 178)" }}
              onClick={() => !disabled && handleIconClick(idInputRef)}
            />
          </p>
        )}
      </div>

      <div className="id_document">
        <h6>
          Address Proof Documents <AiOutlineInfoCircle />
        </h6>
        <div className="content">
          <FileUploader
            onChange={(file) => ImageUploadHandler("addressProof", file)}
            file={addressProof}
            types={fileTypes}
            classes="file_uploader"
            disabled={disabled || addressProof === ""}
          />
          {addressProof && (
            <div className="document_image">
              <img
                src={!addressProof?.name ? addressProof : URL.createObjectURL(addressProof)}
                alt=""
                style={{ pointerEvents: disabled ? "none" : "auto" }}
              />
            </div>
          )}
          {flagAddress && <PercentageLoader percentage={uploadPercentage} />}
        </div>
        {addressProof && (
          <p>
            <input
              type="file"
              style={{ display: "none" }}
              ref={addressInputRef}
              onChange={(e) => handleFileChange("addressProof", e)}
              accept={fileTypes.map((type) => `.${type}`).join(", ")}
              disabled={disabled}
            />
            <AiOutlineUpload
              style={{ cursor: disabled ? "not-allowed" : "pointer", marginLeft: "10px", fontSize: "24px", fontWeight: "bold", strokeWidth: "50", color: "rgb(73, 113, 178)" }}
              onClick={() => !disabled && handleIconClick(addressInputRef)}
            />
          </p>
        )}
      </div>

      <div className="id_document">
        <h6>
          ID Photo <AiOutlineInfoCircle />
        </h6>
        <div className="content">
          <FileUploader
            onChange={(file) => ImageUploadHandler("profileImage", file)}
            file={IDPhoto}
            types={fileTypes}
            classes="file_uploader"
            disabled={disabled || IDPhoto === ""}
          />
          {IDPhoto && (
            <div className="document_image">
              <img
                src={!IDPhoto?.name ? IDPhoto : URL.createObjectURL(IDPhoto)}
                alt=""
                style={{ pointerEvents: disabled ? "none" : "auto" }}
              />
            </div>
          )}
          {flagphotoId && <PercentageLoader percentage={uploadPercentage} />}
        </div>
        {IDPhoto && (
          <p>
            <input
              type="file"
              style={{ display: "none" }}
              ref={idphotoInputRef}
              onChange={(e) => handleFileChange("profileImage", e)}
              accept={fileTypes.map((type) => `.${type}`).join(", ")}
              disabled={disabled}
            />
            <AiOutlineUpload
              style={{ cursor: disabled ? "not-allowed" : "pointer", marginLeft: "10px", fontSize: "24px", fontWeight: "bold", strokeWidth: "50", color: "rgb(73, 113, 178)" }}
              onClick={() => !disabled && handleIconClick(idphotoInputRef)}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default UploadDocNew;
