import { gpsDisabled } from "assets/images";
import React, { useState ,useEffect} from "react";
import { AiOutlineDownload } from "react-icons/ai";
import {
  FaRegCalendarAlt,
  FaRegClock,
  FaRegUser,
  FaChevronCircleLeft,
} from "react-icons/fa";
import "./LectureVideo.scss";
import CommonModal from "components/modal-box/CommonModal";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-hot-toast";
import { userLectureDetail, userRelatedLectures } from "services/lecture";
import LectureDetailSkeleton from "components/skeleton/LectureDetailSkeleton";
import {
  charLimit,
  lectureAccess,
  lectureIsExpired,
  lectureIsStarted,
  momentDateFormat,
} from "config/utils";
import moment from "moment";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import ApexVideo from "components/video-player/ApexLecture"
import { getUserIp } from "services/third-party";
import {
  errorResponse,
  failResponse,
  normalDecryptData,
  normalEncryptData,
} from "config/config";
import VideoJsCipherPlayer from "components/video-player/VideoJsCipherPlayer";
import { useCourse } from "context/context";
import LiveLectureDetailSkeleton from "components/skeleton/LiveLectureDetailSkeleton";
import LectureNotFound from "components/Not-Found/LectureNotFound";
import { getSignedUrl } from "services/master";
import { PlayApexLecture } from "services/lecture";


function LectureVideo() {
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lectureDetail, setLectureDetail] = useState({});
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [lecture, setLecture] = useState({});
  const [relatedLectures, setRelatedLectures] = useState([]);
  const [, setTotal] = useState(0);
  const [accessStatus, setAccessStatus] = useState(true);
  const [locationStatus, setLocationStatus] = useState(true);
  const { lectureId } = useParams();
  const { course } = useCourse();
  const location = useLocation();
  const navigate = useNavigate();
  const mainLectureId = normalDecryptData(lectureId, "route")?.split(",");
  const [videoUrl, setVideoUrl] = useState("");


  const [submitLoading, setSubmitLoading] = useState(false);
  const [relatedLecturesLoading, setRelatedLecturesLoading] = useState(false);


  // navigation handler
  const navigationHandler = (id, startDate, endDate, time) => {
    if (+id === +mainLectureId[0]) {
      return;
    }

    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (lectureIsStarted(startDate, time)) {
      toast.error("Lecture is not started");
      return;
    }
    if (lectureIsExpired(endDate, time)) {
      toast.error("Lecture is expired");
      return;
    }
    if (lectureAccess(startDate, endDate)) {
      navigate(`/dashboard/lecture/detail/${normalEncryptData(id, "route")}`, {
        state: { auth: true },
      });
    }
  };

  

  const notesDownloader = async (url) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: url }));
    setSubmitLoading(false);
  };

  // ----------------------- getting lecture detail data -------------------------//
  React.useLayoutEffect(() => {
    // get lecture series detail
    const getLectureDetails = async (lat, long) => {
      try {
        setLoading(true);
        const { data: ip } = await getUserIp();
        const res = await userLectureDetail({
          lecture_id: mainLectureId[0],
          is_exceptional: mainLectureId[1] || 0,
          courseId: course.courseId,
          latitude: lat,
          longitude: long,
          ip: ip.ip,
        });

        if (res.code === 200) {
          setLectureDetail(res.data.lectureDetail || {});
          setLecture(res.data.lectureDetail?.LectureDetail || {});
          if (!res.data.lectureDetail?.LectureDetail?.IsYoutube && !res.data.lectureDetail?.LectureDetail?.IsApex) {
            setOtp(res.data.vdocipherOtp.otp);
            setPlaybackInfo(res.data.vdocipherOtp.playbackInfo);
          }

          setLoading(false);
        } else if (res.code === 101) {
          setAccessStatus(false);
          failResponse(res);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    // get related lectures
    const getRelatedLectureDetails = async () => {
      try {
        // Show loader
        setRelatedLecturesLoading(true);

        // Fetch related lectures
        const { data: ip } = await getUserIp();
        const res = await userRelatedLectures({
          lecture_id: mainLectureId[0],
          courseId: course.courseId,
          ip: ip.ip,
        });

        if (res.code === 200) {
          setRelatedLectures(res.data.relatedLectures.rows || []);
          setTotal(res.data.relatedLectures.count || 0);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      } finally {
        // Hide loader
        setRelatedLecturesLoading(false);
      }
    };


    if (course.courseId) {
      // checks gps is enabled
      if (course.isGps) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
      }

      // if gps enable is false
      if (!course.isGps) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              getLectureDetails(latitude, longitude);
            },
            function (error) {
              if (error.code === error.PERMISSION_DENIED) {
                toast.error("Location access was denied by the user.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.POSITION_UNAVAILABLE) {
                toast.error("Location information is unavailable.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else if (error.code === error.TIMEOUT) {
                toast.error("The request to get user location timed out.");
                setLocationStatus(false);
                setAccessStatus(false);
              } else {
                toast.error("An unknown error occurred.");
                setLocationStatus(false);
                setAccessStatus(false);
              }
            }
          );
        } else {
          toast.error("Geolocation is not supported by this browser.");
        }
        //getLectureDetails();
      }

      +mainLectureId[1] !== 1 && getRelatedLectureDetails();
    }

    // eslint-disable-next-line
  }, [course, lectureId]);

  
  
  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const res = await PlayApexLecture({
            lecture_id: lectureId,
          });

        if (res.code === 200) {
          setVideoUrl(res.data);
        } else {
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    if (lecture.IsApex) {
      fetchVideoUrl();
    }
  }, [lecture.IsApex]);
  // const handleNavigate = () => {
  //   const path = "path1"
  //   localStorage.setItem('lectdetail', "lectdetail");
  //   navigate(`/dashboard/lecture`, {
  //     state: {
  //       auth: true,
  //       location: `/dashboard/lecture`,
  //       path: path, // Store the complete detail page URL
  //     },
  //   });
  // };
  const handleNavigate = () => {
    // if (location.state) {
    //   navigate(location.state.location); // Navigate to the stored location
    // } else {
    //   navigate('/dashboard/lecture');
    // }
    navigate(`/dashboard/lecture/${course.courseId}`);
  };
  return (
    <>
      {accessStatus ? (
        <>
          {!location?.state?.auth ? (
            <Navigate to="/dashboard/lecture" />
          ) : (
            <section
              className={`LectureVideo spacing ${+mainLectureId[1] === 1 && "liveLecture_video"
                }`}
            >
              <div className="container">
                <div className="back_button">
                  <button onClick={handleNavigate}>
                    <FaChevronCircleLeft />
                  </button>
                  {/* <button onClick={() => navigate(-1)}>
                    <FaChevronCircleLeft />
                  </button> */}
                </div>
              </div>
              <div className="container">
                {/* loading component */}
                {loading &&
                  (+mainLectureId[1] !== 1 ? (
                    <LectureDetailSkeleton />
                  ) : (
                    <LiveLectureDetailSkeleton />
                  ))}
                {/* related lectures loading component */}
                {relatedLecturesLoading && (
                  <div className="relatedLecturesLoader">
                    {/* Add your loader component here */}
                  </div>
                )}
                {/* main component */}
                {!loading && !relatedLecturesLoading && (
                  <>
                    {+mainLectureId[1] !== 1 && (
                      <div className="courseList">
                        <ul>
                          {/* related lecture not found */}
                          {relatedLectures?.length <= 0 && (
                            <li>No Related Lectures Available</li>
                          )}

                          {/* related lecture listing */}
                          {relatedLectures &&
                            relatedLectures.map((data, i) => {
                              return (
                                <li
                                  key={i}
                                  onClick={() =>
                                    navigationHandler(
                                      data.LectureID,
                                      data?.dateScheduled,
                                      data?.lectureenddate,
                                      moment(data?.dateScheduled).format(
                                        "HH:mm"
                                      )
                                    )
                                  }
                                  role="button"
                                  className={`lecture-card ${!lectureAccess(
                                    data?.dateScheduled,
                                    data?.lectureenddate
                                  ) && "disabled"
                                    }`}
                                >
                                  <h5>{charLimit(data.LectureTitle, 30)}</h5>
                                  <p>
                                    <span>
                                      <FaRegUser />
                                      {`${data.users?.FirstName}${data.users?.MiddleName
                                          ? ` ${data.users?.MiddleName} `
                                          : " "
                                        }${data.users?.LastName}`}
                                    </span>
                                    <span>
                                      <FaRegClock /> {data.Duration} mins
                                    </span>
                                  </p>
                                  {+data.LectureID === +mainLectureId[0] && (
                                    <span className="upcomingTag">
                                      Active Video
                                    </span>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                    <div className="course_video">
                      <div className="video_wrap">
                        {/* vdo cipher video player */}
                        {!lecture.IsYoutube && !lecture.IsApex && (
                          <div className="vdocipher_video">
                            {/* display video if otp & playbackInfo is available */}
                            {otp && playbackInfo && (
                              <>
                                <VideoJsCipherPlayer
                                  otp={otp}
                                  playbackInfo={playbackInfo}
                                  isTimeSpentApplicable={
                                    !!lectureDetail?.istimespent
                                  }
                                  lastSeekBar={lectureDetail?.lastPlay}
                                />
                              </>
                            )}
                          </div>
                        )}

                        {/* youtube video player */}
                        {lecture.IsYoutube && (
                          <div className="youtube_video">
                            <YoutubeVideo
                              src={lecture.VideoFile}
                              width="100%"
                              height="400"
                            />
                          </div>
                        )}

                        {/* youtube video player */}
                        {lecture.IsApex && (
                          <div className="youtube_video">
                            <ApexVideo
                              src={videoUrl}
                              width="100%"
                              height="400"
                            />
                          </div>
                        )}


                        <div className="about_video">
                          <div className="content">
                            <h5>{lectureDetail.lecturetitle}</h5>
                            <ul>
                              <li>
                                <FaRegUser />
                                {`${lecture?.users?.FirstName}${lecture?.users?.MiddleName
                                    ? ` ${lecture?.users?.MiddleName} `
                                    : " "
                                  }${lecture.users?.LastName}`}
                              </li>
                              <li>
                                <FaRegClock /> {lecture.Duration} mins
                              </li>
                              <li>
                                <FaRegCalendarAlt />{" "}
                                {momentDateFormat(lectureDetail.dateScheduled)}
                              </li>
                              <li className="red">
                                <FaRegCalendarAlt />
                                {momentDateFormat(lectureDetail.lectureenddate)}
                              </li>
                            </ul>
                          </div>
                          <div className="button_wrap">
                            <button
                              type="button"
                              className="dashboard_button fill"
                              onClick={() => notesDownloader(lecture.Notes)}
                              disabled={!lecture.Notes || submitLoading}
                            >
                              <AiOutlineDownload />
                              Download notes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <CommonModal
                modalShow={modalShow}
                handleClose={() => setModalShow(false)}
                title={"Modal heading"}
              ></CommonModal>
            </section>
          )}
        </>
      ) : (
        <section className="LectureVideo liveLecture_video spacing">
          <div className="container">
            <div className="back_button">
              <button onClick={() => navigate(-1)}>
                <FaChevronCircleLeft />
              </button>
            </div>
          </div>
          <div className="container">
            <div className="course_video">
              <div className="video_wrap">
                <LectureNotFound
                  note={
                    locationStatus
                      ? `This lecture is not allowed to play at this location`
                      : `location is required, please allow location`
                  }
                  image={gpsDisabled}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default LectureVideo;
