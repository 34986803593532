import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Calendarimg, Peopleimg } from "assets/icons";
import { BsArrowRight } from "react-icons/bs";
import { Courseimg1 } from "assets/images";
import { Nav, Tab } from "react-bootstrap";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { charLimit, countArray, momentDateFormat, sliderItem } from "config/utils";
import { courseByExam } from "services/center";
import { errorResponse, failResponse } from "config/config";

const UpcomingCourses = ({ exam, centerId, city, phoneNumber, affiliatecenter }) => {
  const [selectedExam, setSelectedExam] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedCourses, setSelectedCourses] = useState([]);

  const isMobile = window.innerWidth <= 768;
  let gridTemplateColumns;
  if (isMobile) {
    gridTemplateColumns = '1fr';
  } else if (selectedCourses.length === 1) {
    gridTemplateColumns = `1fr' : 'repeat(4, 1fr)`;
  } else if (selectedCourses.length === 2) {
    gridTemplateColumns = `repeat(2, 2fr)`;
  }else if (selectedCourses.length === 3) {
    gridTemplateColumns = `repeat(3, 3fr)`;
  } else {
    gridTemplateColumns = 'repeat(4, 1fr)';
  }
  
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    gap: '20px',
    placeItems: 'center',
  };
  


  

  var Coursecard = {
    dots: false,
    arrows: true,
    slidesToShow: sliderItem(selectedCourses, 5),
    slidesToScroll: 1,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: sliderItem(selectedCourses, 4),
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  var CoursecardTitle = {
    dots: false,
    arrows: true,
    slidesToShow: sliderItem(exam, 5),
    slidesToScroll: 2,
    infinite: true,
    speed: 900,
    autoplaySpeed: 8000,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // --------------- getting course according to subject ----------------//
  React.useEffect(() => {
    const getUpcomingCourse = async () => {
      try {
        setLoading(true);
        const res = await courseByExam({
          examType: selectedExam,
          centerpageId: centerId,
        });

        if (res.code === 200) {
          setSelectedCourses(res.data.centerData || []);
          setLoading(false);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (selectedExam && centerId) {
      getUpcomingCourse();
    }
    // eslint-disable-next-line
  }, [selectedExam, centerId]);

  React.useLayoutEffect(() => {
    setSelectedExam(exam?.[0]?.id);
    // eslint-disable-next-line
  }, [exam]);

  return (
    <>
      {exam.length > 0 && (
        <div className="exam_courses spacing">
          <div className="container">
            <div className="page_title padding">
              <h3>Upcoming courses at {city} Centre</h3>
              <p>
                For best deals and offers, get in touch with affiliate
                <a
                  href={`tel:${phoneNumber}`}
                  className="text-decoration-underline ms-2"
                >
                  <b>{phoneNumber}</b>
                </a>
              </p>
            </div>

            <div className="courses_categories">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="courseTitle">
                  <Nav variant="pills" className="" activeKey={true}>
                    <Slider {...CoursecardTitle}>
                      {exam &&
                        exam.map(({ id, title }) => {
                          return (
                            <Nav.Item
                              key={id}
                              onClick={() => setSelectedExam(id)}
                            >
                              <Nav.Link
                                eventKey={id}
                                className={
                                  selectedExam === id ? "active" : "inactive"
                                }
                              >
                                {title}
                              </Nav.Link>
                            </Nav.Item>
                          );
                        })}
                    </Slider>
                  </Nav>
                </div>
                <div className="navtab_content">
                  <Tab.Content>
                    <Tab.Pane
                      eventKey={selectedExam}
                      active={selectedExam}
                      activekey={selectedExam}
                    >
                      {/************** loading component **************/}
                      {loading && (
                        <div className="d-flex">
                          {countArray(4).map((data) => {
                            return (
                              <div className="w-25 mx-1" key={data}>
                                <CourseSkeleton width="100%" height={350} />
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {/************** not found component **************/}
                      {!loading && selectedCourses.length <= 0 && (
                        <div className="coursecard_wrap">
                          <div className="content text-center text-danger py-5">
                            <h6>No Course Available</h6>
                          </div>
                        </div>
                      )}

                      {/************** main component **************/}
                      {!loading && selectedCourses.length > 0 && (
                        
                        
                        <div className="coursecard_wrap" style={gridStyle}>
                          {/* <Slider {...Coursecard}> */}
                          {selectedCourses.map((course, i) => {
                            return (
                              <div className="course_card" key={i}>
                                <div className="image_wrap">
                                  <img
                                    src={course?.image || Courseimg1}
                                    alt={course?.DisplayNameEng}
                                  />
                                </div>
                                <div className="content">
                                  <div className="course_detail">
                                    <h5>{charLimit(course?.DisplayNameEng, 55)}</h5>
                                    <ul>
                                      <li>
                                        <img
                                          src={Calendarimg}
                                          alt=""
                                          loading="lazy"
                                        />
                                        Starting from
                                        <b>
                                          {momentDateFormat(
                                            course?.CourseStartDate
                                          )}
                                        </b>
                                      </li>
                                      <li>
                                        <img
                                          src={Peopleimg}
                                          alt=""
                                          loading="lazy"
                                        />
                                        {course?.CourseType === "Both"
                                          ? "Hybrid"
                                          : course?.CourseType}
                                      </li>
                                      <div className="button_wrap">
                                        <Link
                                          // to={`/course-detail/${course?.CourseTitle}/${course?.id}`}
                                          to={
                                            course?.id === '7f77e12b-c186-4934-9bfc-abf3f3ff4a28'
                                              ? `/ncert-foundation-course`
                                              : course?.id === 'f64976d1-9cda-45a2-aa84-13fc66534315'
                                                ? `/psir-optional-classroom-course`
                                                : course?.id === 'ee52f2c9-6e82-4c89-b6ca-01f13b6335e9'
                                                  ? `/psir-answer-writing-module`
                                                  : course?.id === '2e87fe0b-1f18-4aad-aac8-ad3fb389c0e2'
                                                    ? `/psir-optional-online-course`
                                                    : course?.id === '5457a2b8-6314-4b32-8c61-0d750382104e'
                                                      ? `/psir-on-demand-tablet-course`
                                                      : course?.id === '280e4431-6460-463c-aace-081bc8c4cce7'
                                                        ? `/psir-postal-correspondence-course`
                                                        : course?.id === '22f0178d-e5be-4d1d-a524-ff247f882c47'
                                                          ? `/history-optional-classroom-course`
                                                          : course?.id === 'd22dea14-d7ce-4e06-a972-f9c3ef6518a3'
                                                            ? `/history-optional-online-course`
                                                            : course?.id === '56801f1b-ff6d-407e-b4a6-3a53d1f3a7b0'
                                                              ? `/sociology-optional-classroom-course`
                                                              : course?.id === '7e316a86-a888-4794-aef5-b4a69766e825'
                                                                ? `/sociology-optional-online-course`
                                                                : course?.id === '31547019-950b-41a1-9d38-3b9a455b0904'
                                                                  ? `/sociology-on-demand-tablet-course`
                                                                  : course?.id === '9be8b42a-ea81-4f5b-8cb5-18b0e57b1c3e'
                                                                    ? `/sociology-test-series`
                                                                    : course?.id === '0d04b1e8-4b67-44aa-8a54-afdafae1d7cf'
                                                                      ? `/gs-foundation-classroom-course`
                                                                      : course?.id === '2269626d-c63f-4cdf-b270-1285c4c3f11e'
                                                                        ? `/gs-foundation-online-course`
                                                                        : course?.id === 'a5db0ae6-f2b0-4de6-a4ac-356262aea68d'
                                                                          ? `/qep-gs-crash-course-classroom`
                                                                          : course?.id === 'b0f96a3d-250a-4769-bdff-3e223df65efd'
                                                                            ? `/qep-gs-crash-course-online`
                                                                            : course?.id === '06f634ce-d49a-4327-bd9e-fc412d3f0e48'
                                                                              ? `/Integrated-GS-and-PSIR-Optional-Classroom-Course`
                                                                              : course?.id === '66a37d9f-abd6-4822-b56d-559db8a9cc63'
                                                                                ? `/Integrated-GS-and-Sociology-Optional-Classroom-Course`
                                                                                : course?.id === '69204890-d049-4521-9f0d-594b1d624d4c'
                                                                                  ? `/Integrated-GS-and-History-Optional-Classroom-Course`
                                                                                  : course?.id === 'dbb6e35d-f703-4778-8157-6b3c89ecb343'
                                                                                    ? `/Integrated-GS-and-PSIR-Optional-Online-Course`
                                                                                    : course?.id === 'df9db5b3-e8f1-428e-885c-68c91747e9c0'
                                                                                      ? `/Integrated-GS-and-Sociology-Optional-Online-Course`
                                                                                      : course?.id === '84f8cf5d-482e-42ec-a49a-b00f434ecf57'
                                                                                        ? `/Integrated-GS-and-History-Optional-Online-Course`
                                                                                        : course?.id === 'bd04e35a-8620-465e-bc4a-b2b516cdbfb4'
                                                                                          ? `/csat-foundation-course`
                                                                                          : course?.id === '53a90166-3367-45b1-b950-70bc3f41187b'
                                                                                            ? `/essay-program`
                                                                                            : `/course-detail/${course?.CourseTitle}/${course?.id}/${affiliatecenter}`
                                          }
                                          state={{ previousPath: "/upcoming-courses" }}

                                          className="button"
                                        >
                                          View Details
                                          <span className="icon">
                                            <BsArrowRight />
                                          </span>
                                        </Link>
                                      </div>
                                      {/* <li>
                                        <img src={Pageimg} alt="" loading="lazy"/>
                                        26 Lectures (Static)
                                      </li> */}
                                      {/* <li>
                                        <h6 className="price">
                                          <BiRupee />
                                          {course?.SRNewStudentCourseFee ||
                                            "00"}
                                          /-
                                        </h6>
                                        <span>Inclusive of taxes</span>
                                      </li> */}
                                    </ul>
                                  </div>
                                  {/* <div className="button_wrap">
                                    <Link
                                      to={`/course-detail/${course.CourseTitle}/${course.id}`}
                                      className="button"
                                    >
                                      Enroll Now
                                      <span className="icon">
                                        <BsArrowRight />
                                      </span>
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                            );
                          })}
                          {/* </Slider> */}
                          {/* <h6 className="text-center mt-3">
                            For best deals and offers, get in touch with
                            affiliate
                            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                          </h6> */}

                          {/* <div className="button_wrap upcom_course">
                            <Link to="/course-listing" className="button">
                              View all courses
                              <span className="icon">
                                <BsArrowRight />
                              </span>
                            </Link>
                          </div> */}


                        </div>
                        
                        
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingCourses;
