import React, { useState ,useEffect} from "react";
import "./CourseSubscription.scss";
import { Input } from "components/Form";
import { BiRupee } from "react-icons/bi";
import { Select, TextArea } from "components/Form";
import { updateUserProfile } from "services/dashboard";

import {
  Link,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";
import UploadDoc from "dashboard/edit-profile/UploadDoc";
import { toast } from "react-hot-toast";
import { userProfile } from "services/dashboard";
import { getCoupons } from "services/courses/coupons";
import { getBillingCourseDetailById } from "services/courses/courses";
import useButtonLoader from "hooks/useButtonLoader";
import Payment from "./section/Payment";
import { useAddons } from "context/context";
import CourseSubscriptionSkeleton from "components/skeleton/CourseSubscriptionSkeleton";
import { FaTimesCircle } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";
import { CourseBookImg } from "assets/images";
import { FcTabletAndroid } from "react-icons/fc";
import useDebounce from "hooks/useDebounce";
import { getCourseVerticals, getAllStates } from "services/master";


function CourseSubscription() {
  const { courseId, courseName, centerid } = useParams();
  const navType = useNavigationType();
  const navigate = useNavigate();
  const [couponToggle, setCouponToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);

  const [tabData, setTabData] = useState([]);
  const [tablet, setTablet] = useState(true);

  const debouncedTablet = useDebounce(tablet);

  const [isReadOnly, setIsReadOnly] = useState(false);

  const [buttonRef2, setButtonLoading2] = useButtonLoader("Proceed to payment");

  const [courseDetails, setCourseDetails] = useState({
    admissionCenter: "",
    conveyanceFee: 0,
    couponCode: "",
    couponCodeApplied: 0,
    courseFee: 0,
    gst: 0,
    isOldStudent: 0,
    newStudentCourseFee: 0,
    oldStudentCourseFee: 0,
    title: " English",
    total: 0,
    gstFee: 0,
  });

  const { addons } = useAddons();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Apply");

  const [isEditable, setIsEditable] = useState({
    // profile: false,
    idProof: false,
    addressProof: false,
    idphoto: false
  });

  const [userData, setUserData] = useState({});
  const [mobError, setMobError] = useState("");
  const [emobError, seteMobError] = useState("");


  const [errorField, setErrorField] = React.useState({
    firstName: "",
    lastName: "",
    Mobile: "",
    EmergencyContactNo: "",
    Education: "",
    PHouseNo: "",
    PStreet: "",
    PLandmark: "",
    PCity: "",
    PState: "",
    PZipCode: "",
    THouseNo: "",
    TStreet: "",
    TLandmark: "",
    TCity: "",
    TState: "",
    TZipCode: "",
  });

  const [isSameAddress, setIsSameAddress] = React.useState(
    userData.PHouseNo === userData.THouseNo &&
    userData.PStreet === userData.TStreet &&
    userData.PLandmark === userData.TLandmark &&
    userData.PCity === userData.TCity &&
    userData.PState === userData.TState &&
    userData.PZipCode === userData.TZipCode
  );

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!userData.firstName) {
      errorField["firstName"] = "Please Enter First Name";
      formIsValid = false;
    }
    if (!userData.lastName) {
      errorField["lastName"] = "Please Enter Last Name";
      formIsValid = false;
    }

    if (!userData.Mobile) {
      errorField["Mobile"] = "Please Enter Mobile No.";
      formIsValid = false;
    }
    if (!userData.EmailID) {
      errorField["EmailID"] = "Please Enter Email ID";
      formIsValid = false;
    }
    if (!userData.EmergencyContactNo) {
      errorField["EmergencyContactNo"] = "Please Enter Emergency No.";
      formIsValid = false;
    }
    if (!userData.Education) {
      errorField["Education"] = "Please Enter Education";
      formIsValid = false;
    }

    if (!userData.PHouseNo) {
      errorField["PHouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userData.PStreet) {
      errorField["PStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userData.PLandmark) {
      errorField["PLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userData.PCity) {
      errorField["PCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userData.PState) {
      errorField["PState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userData.PZipCode) {
      errorField["PZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    if (!userData.THouseNo) {
      errorField["THouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userData.TStreet) {
      errorField["TStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userData.TLandmark) {
      errorField["TLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userData.TCity) {
      errorField["TCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userData.TState) {
      errorField["TState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userData.TZipCode) {
      errorField["TZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    if (isEditable.idProof) {
      formIsValid = false;
    }

    if (isEditable.addressProof) {
      formIsValid = false;
    }

    if (isEditable.idphoto) {
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  //   onchange handler for edit profile
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
  
    // Define a function to update the state with sanitized values
    const sanitizeInput = (name, value) => {
      let updatedValue = value;
  
      if (["TZipCode", "PZipCode", "EmergencyContactNo"].includes(name)) {
        updatedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      }
  
      if (["TState", "PState", "TCity", "PCity"].includes(name)) {
        updatedValue = value.replace(/[^A-Za-z ]/g, "").replace(/\s+/g, " "); // Allow only letters and spaces
      }
  
      return updatedValue;
    };
    
    if (name === "Mobile") {
      // Mobile number validation
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length === 10) {
        setMobError("");
      } else {
        setMobError("Mobile number must be 10 digits");
      }
    } 
    if (name === "EmergencyContactNo") {
      // Mobile number validation
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length === 10) {
        seteMobError("");
      } else {
        seteMobError("Mobile number must be 10 digits");
      }
    } 
    // Sanitize the input value
    const sanitizedValue = sanitizeInput(name, value);
  
    // Update the state with sanitized values
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: sanitizedValue,
    }));
  
    // If same address flag is true and one of the temporary address fields is changed, set the flag to false
    if (isSameAddress && ["THouseNo", "TState", "TCity", "TZipCode", "TLandmark", "TStreet"].includes(name)) {
      setIsSameAddress(false);
    }
  };
  
  // Separate function to handle API call after state update
  const handleProfileUpdate = async () => {
    const res = await updateUserProfile({
      firstName: userData.firstName,
      middleName: userData.middleName,
      lastName: userData.lastName,
      Mobile: userData.Mobile,
      EmergencyContactNo: userData.EmergencyContactNo,
      Education: userData.Education,
      PHouseNo: userData.PHouseNo,
      PStreet: userData.PStreet,
      PLandmark: userData.PLandmark,
      PCity: userData.PCity,
      PState: userData.PState,
      PZipCode: userData.PZipCode,
      THouseNo: userData.THouseNo,
      TStreet: userData.TStreet,
      TLandmark: userData.TLandmark,
      TCity: userData.TCity,
      TState: userData.TState,
      TZipCode: userData.TZipCode,
    });
  
    if (res.code === 200) {
      // toast.success(res.message);
    } else {
      // toast.error(res.message);
    }
    // if (res.code === 200) {
    //   console.log("success")
    // } else {
    //   console.log("failed")
    // }
    setSubmitLoading(false);
  };
  
  // Use an effect to trigger the profile update after the userData state is updated
  useEffect(() => {
    localStorage.setItem("supcoursename", "supcoursenameremove");
    localStorage.setItem("supcourseid", "supcourseidremove");
    if (userData) {
      handleProfileUpdate();
    }
  }, [userData]);
  
  


  // get user profile
  const getUserProfile = async () => {
    try {
      const res = await userProfile();
      const { IDProof, addressProof,IDPhoto, ...rest } = userData;
      if (res.code === 200) {
        setUserData({ ...res.data.userDetails, ...rest } || {});
        // checks user's profile is editable or not
        setIsEditable({
          // profile: res.data.userDetails.PHouseNo ? false : true,
          idProof: res.data.userDetails.IDProof ? false : true,
          addressProof: res.data.userDetails.addressProof ? false : true,
          idphoto: res.data.userDetails.IDPhoto ? false : true,
        });
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };
  // const getUserProfile = async () => {
  //   try {
  //     const res = await userProfile();
  //     if (res.code === 200) {
  //       const userDetails = res.data.userDetails;
  //       setUserData(userDetails);

  //       // Set initial values for TState and PState dropdowns
  //       setSelectedTState(userDetails.TState || '');
  //       setSelectedPState(userDetails.PState || '');

  //       // Check if user's profile is editable
  //       setIsEditable({
  //         profile: userDetails.PHouseNo ? false : true,
  //         idProof: userDetails.IDProof ? false : true,
  //         addressProof: userDetails.addressProof ? false : true,
  //       });
  //     } else {
  //       failResponse(res);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //   }
  // };

  // get billing course detail by id
  const getBillingCourseDetail = async (couponCode = coupon, isTab = 0) => {
    try {
      setButtonLoading2(true);
      const selectedAddons = addons[courseId];
      const res = await getBillingCourseDetailById({
        courseid: courseId,
        couponCode,
        addon_ids: selectedAddons?.map((data) => data.id),
        purchase_tab: isTab,
      });

      if (res.code === 200) {
        setCourseDetails(res.data.data || {});
        setTabData(res.data.tabData || []);
        setButtonLoading2(false);
      } else if (res.code === 101) {
        failResponse(res);
        setCoupon("");
        setAppliedCoupon("");
        getBillingCourseDetail("");
        setButtonLoading2(false);
      } else {
        failResponse(res);
        setButtonLoading2(false);
      }
    } catch (err) {
      setButtonLoading2(false);
      errorResponse(err);
    }
  };

  // coupon apply handler
  const couponApplyHandler = async (coupon) => {
    if (!coupon) {
      toast.error("Please Enter Coupon Code");
    } else {
      setCoupon(coupon);
      setAppliedCoupon(coupon);
      setSubmitLoading(true);
      await getBillingCourseDetail(coupon);
      setSubmitLoading(false);
    }
  };

  // --------------- getting all required master data ----------------//
  React.useLayoutEffect(() => {
    localStorage.setItem("supcoursename", "supcoursenameremove");
    localStorage.setItem("supcourseid", "supcourseidremove");
    // get user profile
    getUserProfile();
    // eslint-disable-next-line
  }, [flag]);

  //   user selection same as present function
  React.useEffect(() => {
    if (isSameAddress) {
      const { THouseNo, TStreet, TLandmark, TCity, TState, TZipCode } =
        userData;
      setUserData({
        ...userData,
        PHouseNo: THouseNo,
        PStreet: TStreet,
        PLandmark: TLandmark,
        PCity: TCity,
        PState: TState,
        PZipCode: TZipCode,
      });
    } else {
      setUserData({
        ...userData,
        PHouseNo: "",
        PStreet: "",
        PLandmark: "",
        PCity: "",
        PState: "",
        PZipCode: "",
      });
    }
    // eslint-disable-next-line
  }, [isSameAddress]);

  // tab purchase selection
  React.useEffect(() => {
    if (courseDetails.tabCompulsory === 0) {
      getBillingCourseDetail(coupon, debouncedTablet ? 1 : 0);
    }

    // eslint-disable-next-line
  }, [debouncedTablet]);

  // ----------------------- getting course detail data -------------------------//
  React.useLayoutEffect(() => {
    // get coupons by course id
    const getAllCoupons = async () => {
      try {
        const res = await getCoupons({ courseid: courseId });

        if (res.code === 200) {
          setCouponList(res.data.coupans.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // get billing course detail by id
    const getMainBillingCourseDetail = async () => {
      try {
        const selectedAddons = addons[courseId];
        setLoading(true);
        const res = await getBillingCourseDetailById({
          courseid: courseId,
          addon_ids: selectedAddons?.map((data) => data.id),
        });

        if (res.code === 200) {
          setCourseDetails(res.data.data || {});
          setTabData(res.data.tabData || []);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
          getBillingCourseDetail();
        }
      } catch (err) {
        setLoading(false);
        errorResponse(err);
      }
    };

    getAllCoupons();
    getMainBillingCourseDetail();
    // eslint-disable-next-line
  }, [courseId]);

  React.useLayoutEffect(() => {
    if (navType !== "PUSH") {
      navigate(`/course-detail/${courseName}/${courseId}`);
    }
    // eslint-disable-next-line
  }, [navType, navigate]);

  const [allStates, setallStates] = useState([]);
  const [selectedTState, setSelectedTState] = useState('');
  const [selectedPState, setSelectedPState] = useState('');

  React.useLayoutEffect(() => {
    localStorage.setItem("supcoursename", "supcoursenameremove");
    localStorage.setItem("supcourseid", "supcourseidremove");
    const getAllStatesList = async () => {
      try {
        const res = await getAllStates();
        if (res.code === 200) {
          setallStates(res.data.stateslist.rows || []);
          console.log("setallStates", allStates);
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    // calls all the master functions
    getAllStatesList();

    // eslint-disable-next-line
  }, []);

  const handleTStateChange = (e) => {
    setSelectedTState(e.target.value);
    // Update userData with selected TState
    setUserData({ ...userData, TState: e.target.value });
  };

  const handlePStateChange = (e) => {
    setSelectedPState(e.target.value);
    // Update userData with selected PState
    setUserData({ ...userData, PState: e.target.value });
  };



  return (
    <>
      <div className="breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to="/course-listing">Course listing</Link>
            </li>
            <li>
              <Link to={`/course-detail/${courseName}/${courseId}`}>
                Course details
              </Link>
            </li>
            <li>
              <Link to="#">Payment summary</Link>
            </li>
          </ul>
        </div>
      </div>

      <section className="course_subscription spacing">
        <div className="container">
          {/* loading component */}
          {loading && <CourseSubscriptionSkeleton />}

          {/* main component */}
          {!loading && (
            <div className="subscription_content">
              <div className="personal_details">
                <h6>Subscribe</h6>
                <div className="form_group">
                  <div className="form_field_wrap">

                    <div className="form_field">
                      <Input
                        type="text"
                        name="firstName"
                        value={userData.firstName || ""}
                        onChange={onChangeHandler}
                        placeholder="Enter First Name"
                        className={`input form-control`}
                        maxLength={50}
                        label={"First Name "}
                        error={errorField.firstName || ""}
                        required
                      />
                    </div>








                    <div className="form_field">
                      <Input
                        type="text"
                        name="middleName"
                        placeholder="Enter Middle Name"
                        className={`input form-control `}
                        label={"Middle Name"}
                        value={userData.middleName || ""}
                        onChange={onChangeHandler}
                        maxLength={50}
                      />
                    </div>

                    {userData.lastName ? (<div className="form_field">
                      <Input
                        type="text"
                        name="lastName"
                        placeholder="Enter Last Name"
                        className={`input form-control `}
                        label={"Last Name"}
                        value={userData.lastName || ""}
                        onChange={onChangeHandler}
                        maxLength={50}
                        error={errorField.lastName || ""}
                        required
                      />
                    </div>) : (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                          className={`input form-control `}
                          label={"Last Name"}
                          onChange={onChangeHandler}
                          maxLength={50}
                          error={errorField.lastName || ""}
                          required
                        />
                      </div>
                    )}


                  </div>



                  <div className="form_field_wrap">

                  <div className="form_field">
                        <Input
                          type="text"
                          name="Mobile"
                          placeholder="Enter Mobile"
                          className={`input form-control ${mobError && "is-invalid"}`}
                          maxLength={10}
                          value={userData.Mobile || ""}
                          onChange={onChangeHandler}
                          label={"Mobile Number "}
                          error={errorField.Mobile}
                          required
                          readOnly={userData.mobileVerified === true}

                        />
                        {mobError && (
                              <span className="error" style={{ color: '#dc3545', fontSize: '13px', marginLeft: '1px' }}>
                                {mobError}
                              </span>
                            )}
                      </div>

                      <div className="form_field">
                        <Input
                          type="text"
                          name="EmergencyContactNo"
                          placeholder="Enter Emergency Contact No"
                          className={`input form-control ${emobError && "is-invalid"}`}
                          value={userData.EmergencyContactNo || ""}
                          maxLength={10}
                          onChange={onChangeHandler}
                          label={"Emergency Number "}
                          error={errorField.EmergencyContactNo}
                          required

                        />
                        {emobError && (
                              <span className="error" style={{ color: '#dc3545', fontSize: '13px', marginLeft: '1px' }}>
                                {emobError}
                              </span>
                            )}
                      </div>







                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="EmailID"
                        value={userData.EmailID || ""}
                        placeholder="Enter Email Id"
                        className={`input form-control `}
                        maxLength={50}
                        label={"E-mail id "}
                        error={errorField.EmailID}
                        required
                        readOnly
                      />
                    </div>


                    {userData.Education ? (<div className="form_field">
                      <Input
                        type="text"
                        name="Education"
                        value={userData.Education || ""}
                        onChange={onChangeHandler}
                        placeholder="Enter Education"
                        className={`input form-control `}
                        maxLength={50}
                        label={"Education "}
                        error={errorField.Education}
                        required
                      />
                    </div>
                    ) : (
                      <div className="form_field">
                        <Input
                          type="text"
                          name="Education"
                          onChange={onChangeHandler}
                          placeholder="Enter Education"
                          className={`input form-control `}
                          maxLength={50}
                          label={"Education "}
                          error={errorField.Education}
                          required
                        />
                      </div>
                    )}





                  </div>
                </div>

                {/* upload doc component */}
                <div className="dashboard edit_profile spacing">
                  <div className="edit_profile_content">
                    <div className="profile_content">
                      <UploadDoc
                        addressDoc={userData.addressProof || ""}
                        idDoc={userData.IDProof || ""}
                        idPhoto={userData.IDPhoto || ""}
                        isEditable={isEditable}
                        flag={() => setFlag(!flag)}
                      />
                    </div>
                  </div>
                </div>

                <h6>Address</h6>
                <div className="form_group">
                  <b className="_title">Present address</b>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="THouseNo"
                        value={userData.THouseNo || ""}
                        placeholder="Enter House No."
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"House No. "}
                        error={errorField.THouseNo}
                        required
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TStreet"
                        value={userData.TStreet || ""}
                        placeholder="Enter Street Name"
                        className={`input form-control `}
                        maxLength={20}
                        onChange={onChangeHandler}
                        label={"Street Name "}
                        error={errorField.TStreet}
                        required
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TLandmark"
                        value={userData.TLandmark || ""}
                        placeholder="Enter Landmark"
                        className={`input form-control `}
                        onChange={onChangeHandler}
                        maxLength={30}
                        label={"Landmark "}
                        error={errorField.TLandmark}
                        required
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TZipCode"
                        value={userData.TZipCode || ""}
                        placeholder="Enter Zipcode"
                        className={`input form-control `}
                        maxLength={6}
                        label={"Zipcode "}
                        error={errorField.TZipCode}
                        onChange={onChangeHandler}
                        required
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <Input
                        type="text"
                        name="TState"
                        value={userData.TState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.TState}
                        required
                      /> */}
                      <Select
                        name="TState"
                        value={userData.TState || ""}
                        //value={selectedTState}
                        onChange={onChangeHandler}
                        className={`input form-control`}
                        label={"State "}
                      >
                        <option value="">Select State</option>
                        {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))}
                      </Select>
                      {/* Error message for TState */}
                      {errorField.TState && <span className="error">{errorField.TState}</span>}

                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="TCity"
                        value={userData.TCity || ""}
                        placeholder="Enter City"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"City "}
                        error={errorField.TCity}
                        required
                      />
                    </div>
                  </div>
                  <p className="checkbox_wrap">
                    <Input
                      type="checkbox"
                      className="input"
                      id="check"
                      checked={isSameAddress}
                      onChange={() => {
                        setIsSameAddress(!isSameAddress);
                      }}
                      // disabled={!isEditable.profile}
                    />
                    <label htmlFor="check">
                      Permanent Address same as Present Address
                    </label>
                  </p>
                  <b className="_title">Permanent address</b>
                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PHouseNo"
                        value={userData.PHouseNo || ""}
                        placeholder="Enter House No."
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"House No. "}
                        error={errorField.PHouseNo}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PStreet"
                        value={userData.PStreet || ""}
                        placeholder="Enter Street Name"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"Street Name "}
                        error={errorField.PStreet}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PLandmark"
                        value={userData.PLandmark || ""}
                        placeholder="Enter Landmark"
                        className={`input form-control `}
                        onChange={onChangeHandler}
                        maxLength={30}
                        label={"Landmark "}
                        error={errorField.PLandmark}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PZipCode"
                        value={userData.PZipCode || ""}
                        placeholder="Enter Zipcode"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={6}
                        label={"Zipcode "}
                        error={errorField.PZipCode}
                        required
                        disabled={isSameAddress}
                      />
                    </div>
                  </div>

                  <div className="form_field_wrap">
                    <div className="form_field">
                      {/* <Input
                        type="text"
                        name="PState"
                        value={userData.PState || ""}
                        placeholder="Enter State"
                        onChange={onChangeHandler}
                        className={`input form-control `}
                        maxLength={20}
                        label={"State "}
                        error={errorField.PState}
                        required
                        disabled={isSameAddress}
                      /> */}
                      <Select
                        name="PState"
                        //value={selectedPState}
                        value={userData.PState || ""}
                        onChange={onChangeHandler}
                        className={`input form-control`}
                        required
                        label={"State"}
                      >
                        <option value="">Select State</option>
                        {allStates.map((state) => (
                          <option key={state.StateID} value={state.StateName}>
                            {state.StateName}
                          </option>
                        ))}
                      </Select>
                      {/* Error message for PState */}
                      {errorField.PState && <span className="error">{errorField.PState}</span>}
                    </div>
                    <div className="form_field">
                      <Input
                        type="text"
                        name="PCity"
                        value={userData.PCity || ""}
                        onChange={onChangeHandler}
                        placeholder="Enter City"
                        className={`input form-control `}
                        maxLength={20}
                        label={"City "}
                        error={errorField.PCity}
                        disabled={isSameAddress}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="course_detail">
                <h6>Summary</h6>
                <div className="form_group">
                  {+courseDetails?.purchased === 1 ? (
                    <>
                      <div className="already_purchased">
                        <img src={CourseBookImg} alt="" loading="lazy" />
                        <h4>You have already purchased this course.</h4>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>{courseDetails.title}</h5>
                      <ul className="course_bill_summary">
                        {/* <li>
                  <span>Admission Center :</span>{" "}
                  <b>{courseDetails.admissionCenter}</b>
                </li> */}
                        <li>
                          <span>Course Fees :</span>
                          {courseDetails.isOldStudent === 1 ? (
                            <del>
                              <b>
                                <BiRupee /> {courseDetails.courseFee}
                              </b>
                            </del>
                          ) : (
                            <b>
                              <BiRupee /> {courseDetails.courseFee}
                            </b>
                          )}
                        </li>
                        {courseDetails.isOldStudent === 1 && (
                          <li>
                            <span>SR old student fee:</span>
                            <b>
                              <BiRupee /> {courseDetails.oldStudentCourseFee}
                            </b>
                          </li>
                        )}
                        {/* tablet price if user not purchasing tab */}
                        {courseDetails.tabCompulsory === 0 && !tablet && (
                          <li>
                            <span>Tablet Price</span>
                            <del>
                              <b>
                                <BiRupee /> {courseDetails.tabPrice}
                              </b>
                            </del>
                          </li>
                        )}

                        {/* <li>
                      <span>Conveyance fee :</span>
                      <b>
                        <BiRupee />
                        {courseDetails.conveyanceFee}
                      </b>
                    </li>
                     <li>
                  <span>GST ({courseDetails.gst}%) :</span>
                  <b>
                    <BiRupee />
                    {courseDetails.gstFee}
                  </b>
                </li> */}

                        {/* context addon display */}
                        {addons[courseId] && addons[courseId]?.length > 0 && (
                          <div className="my-2">
                            <h6 className="">Addons</h6>
                            {addons[courseId]?.map((addon) => {
                              return (
                                <li key={addon.id}>
                                  <span>{addon.name || "N/A"} :</span>
                                  <b>
                                    <BiRupee />
                                    {addon.price}
                                  </b>
                                </li>
                              );
                            })}
                          </div>
                        )}
                        {/* applied coupon display */}
                        {courseDetails.couponCodeApplied === 1 && (
                          <li>
                            <span>Coupon : {courseDetails.couponCode} </span>
                            <b>
                              <BiRupee />
                              {courseDetails.couponCodeAmount}
                            </b>
                          </li>
                        )}
                      </ul>
                      <div className="couponcode">
                        <div className="applyCoupon">
                          <b>Coupon code</b>
                          <div className="input_wrap">
                            <input
                              type="text"
                              placeholder="Enter coupon code"
                              value={coupon}
                              onChange={(e) => setCoupon(e.target.value)}
                              maxLength={20}
                            />
                            <button
                              type="button"
                              ref={buttonRef}
                              onClick={() => couponApplyHandler(coupon)}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                        {/* hide coupon button if coupons not available */}
                        {couponList && couponList.length > 0 && (
                          <div className="couponCodeListing">
                            <p>
                              <span
                                onClick={() => setCouponToggle(!couponToggle)}
                              >
                                {couponToggle ? "Hide Coupons" : "Show Coupons"}
                              </span>
                            </p>
                            {couponToggle && (
                              <ul>
                                {couponList &&
                                  couponList?.map((couponData, index) => {
                                    return (
                                      <li
                                        className={`${couponData.couponcode?.toLowerCase() ===
                                            appliedCoupon?.toLowerCase()
                                            ? "applied"
                                            : ""
                                          }`}
                                        key={index}
                                      >
                                        <span className="coupon">
                                          {couponData.couponcode}
                                        </span>
                                        <div className="button_wrap">
                                          <button
                                            onClick={() =>
                                              couponApplyHandler(
                                                couponData.couponcode
                                              )
                                            }
                                          >
                                            {couponData.couponcode?.toLowerCase() ===
                                              appliedCoupon?.toLowerCase()
                                              ? "Applied"
                                              : "Apply"}
                                          </button>
                                          {couponData.couponcode?.toLowerCase() ===
                                            appliedCoupon?.toLowerCase() && (
                                              <>
                                                <span
                                                  className="delete_coupon"
                                                  onClick={() => {
                                                    getBillingCourseDetail();
                                                    setCoupon("");
                                                    setAppliedCoupon("");
                                                  }}
                                                >
                                                  <FaTimesCircle />
                                                </span>
                                              </>
                                            )}
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="total_amount">
                        <b>Total amount</b>
                        <b>
                          <BiRupee /> {courseDetails.total}
                        </b>
                      </div>

                      {/* display all active or inactive tablet listing */}

                      {courseDetails.coursePlatform?.includes("Tablet") && (
                        <div className="tab_devices_list">
                          <ul>
                            {tabData &&
                              tabData.map((data) => {
                                return (
                                  <li
                                    className={`tab_item ${data.TabStatus === "Inactive"
                                        ? "inactive"
                                        : "active"
                                      }`}
                                    key={data.TabStudentid}
                                  >
                                    <div className="content">
                                      <FcTabletAndroid />
                                      <b>{data.TabName}</b>
                                      <span className="IMEINumber">
                                        {data.IMEINumber}
                                      </span>
                                    </div>
                                    <span
                                      className={`status ${data.TabStatus === "Inactive"
                                          ? "inactive"
                                          : "active"
                                        }`}
                                    ></span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}

                      {/* display tablet selection option if course platform is tablet and tablet ic compulsory to purchase */}
                      {+courseDetails.tabCompulsory === 0 &&
                        courseDetails.coursePlatform?.includes("Tablet") && (
                          <label className="check_box_wrap pb-0">
                            <input
                              type="checkbox"
                              checked={tablet}
                              onChange={({ target }) =>
                                setTablet(target.checked)
                              }
                            />
                            Do you want to purchase tablet ?
                          </label>
                        )}

                      {/* payment update */}
                      <Payment
                        isEditable={isEditable}
                        handleValidation={handleValidation}
                        userData={userData}
                        courseId={courseId}
                        affiliateCenterNo={centerid}
                        courseDetails={courseDetails}
                        buttonRef={buttonRef2}
                        setButtonLoading={setButtonLoading2}
                      />
                    </>
                  )}
                </div>

                {/* Addon courses section */}
                {/* <h6>Add-on Courses</h6>
            <div className="form_group">
              <div className="row">
                {addons && addons.length > 0 ? (
                  addons.map(({ addon_course_id, name, price }) => {
                    return (
                      <div className="col-6" key={addon_course_id}>
                        <div className="check_box_wrap p-0">
                          <input
                            type="checkbox"
                            checked={selectedAddon.some(
                              (data) => data.id === addon_course_id
                            )}
                            onChange={() =>
                              addSelectionHandler({
                                id: addon_course_id,
                                name,
                                price,
                              })
                            }
                            id={1}
                          />
                          <div
                            className="addon"
                            role="button"
                            onClick={() =>
                              addSelectionHandler({
                                id: addon_course_id,
                                name,
                                price,
                              })
                            }
                          >
                            <span className="addon-title">{name}</span>
                            <span className="addon-price">{price}/-</span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="col-12 text-center">
                      <h6>Addons Not Available</h6>
                    </div>
                  </>
                )}
              </div>
            </div> */}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default CourseSubscription;
