import React from 'react'
import { Logo } from "assets/images/index";
import { Link} from "react-router-dom";
import { LocationImg, PhoneImg, TwitterLogo } from "assets/icons";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTelegramPlane,
  FaTimes,
} from "react-icons/fa";
import toast from "react-hot-toast";


function DeleteAccount() {
  return (

    <>

<header className="header">
          <div className="container">
            {/* <div
              className="mobile_button"
              onClick={() => {
                setMobileMenu(!mobileMenu);
              }}
            >
              <img src={Mobilemenu} alt="" loading="lazy" />
            </div> */}
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="Shubra ranjan" loading="lazy" />
              </Link>
            </div>
             
          </div>
        </header>
    <div className="terms_condition spacing">
			<div className="container">
				<h1>Delete Account</h1>

                <div className="terms_condition_content spacing">
                <p>
                    Dear User,
                    <br></br>
                    <br></br>


                    If you are a subscribed user and want to delete account permanently,
                    kindly send email to admin@shubhraranjan.com
                    from your registered email id and mention registered phone number in the email.<br></br> 
                    Your account will be permanently deleted in 8 days.

                    <br></br>
                    <br></br>

                    Regds,
                    <br></br>
                    Admin
                    <br></br>
                    Shubhra Ranjan IAS Academy
                </p>
                </div>
			</div>
		</div>

        <footer className="footer">
      <div className="footer_top">
        <div className="container">
          <div className="footer_logo">
            <img src={Logo} alt="Shubra Ranjan" loading="lazy" />
          </div>
          <div className="footer_content">
            <div className="content_left">
              <ul className="text_content">
                <li>
                  <img src={LocationImg} alt="location" loading="lazy" />
                  <p>
                  12B, Bada Baazar Road, Old Rajinder Nagar, New Delhi - 110060
                  </p>
                </li>
                <li>
                  <img src={PhoneImg} alt="phone" loading="lazy" />
                  <a href="tel:9810212719">9810212719</a>
                </li>
              </ul>
              <ul>
                <li className="title">Ventures</li>
                <li>
                  <Link to="https://instituteforhumanities.com/index.php/home" target="_blank">IFH ( Institute For Humanities )</Link>
                </li>
              </ul>
              <ul>
                <li className="title">Help</li>
                {/* <li>
                  <Link to="">Contact Us</Link>
                </li> */}
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
              </ul>
              <ul>
                <li className="title">Company</li>
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>

            {/* Subscribe to the Newsletter */}
            <div className="content_right">
              {/* <h5>Subscribe to the Newsletter</h5>
              <p>
                to get daily updates on current affairs and free materials,
                directly into your inbox
              </p>
              <div className="footer_form">
                <form>
                  <input type="email" placeholder="Your email address..." />
                  <button type="button" className="button">
                    Submit
                  </button>
                </form>
              </div> */}
              <div className="social_icons">
                <h6>Social Connects</h6>
                <ul>
                  <li>
                    <button
                      onClick={() =>
                        window.open("https://www.facebook.com/shubhraranjanias")
                      }
                      className="facebook"
                    >
                      <FaFacebookF />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open(
                          "https://in.linkedin.com/company/shubhra-viraj-edutech?trk=similar-pages"
                        )
                      }
                      className="linkedin"
                    >
                      <FaLinkedinIn />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open("https://twitter.com/shubhraranjan")
                      }
                      className="twitter"
                    >
                      {/* <FaTwitter /> */}
                      <img src={TwitterLogo} alt="" loading="lazy" />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/shubhraranjanias/?igshid=6h52c5f71k4d"
                        )
                      }
                      className="instagram"
                    >
                      <FaInstagram />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => window.open("https://t.me/sriasofficial")}
                      className="telegram"
                    >
                      <FaTelegramPlane />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <ul>
            <li>
              <Link to="/privacy-policy">Our Privacy Policy</Link>
            </li>
            <li>©. Copyright Shubhra Ranjan IAS Study Pvt. Ltd. © 2024. All Rights Reserved Site</li>
          </ul>
        </div>
      </div>
    </footer>
    </>
  )
}

export default DeleteAccount
