import React from "react";
import { Nav, Tab } from "react-bootstrap";
import "./TestSeries.scss";

import ListViewTest from "./sections/ListViewTest";
import CalenderViewTest from "./sections/CalenderViewTest";
import NotAttemptedView from "./sections/NotAttemptedView";
import { useCourse } from "context/context";
import TestNotFound from "components/Not-Found/TestNotFound";
import { useSearchParams } from "react-router-dom";

function TestSeries() {
  const [searchParam, setSearchParam] = useSearchParams();
  const [view, setView] = React.useState(searchParam.get("view") || "list");
  const { course } = useCourse();
  const isLoading = !course?.courseName;

  return (
    <div className="dashboard lecture_page test_series">
      {/* <div className="dashboard_banner container spacing">
        <div className="container">
          <div className="content">
            <h3>Test series</h3>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam.
            </p>
          </div>
        </div>
        
      </div> */}

      <div className="dashboard_banner container text_banner">
        <div className="container">
        <h3 className="testseries hide-on-mobile">
          {isLoading ? (
              <>
                Test Series of{" "}
                <div className="loading-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </>
            ) : (
              `Test Series of ${course.courseName}`
            )}
          </h3>
          <h3 className="testseries show-on-mobile">
            Test Series
          </h3>
        </div>
      </div>

      <div className="lecture_content spacing">
        {/* if test is in offering and addons */}
        {course.isTestAvailable && (
          <div className="container">
            <Tab.Container id="left-tabs-example">
              <div className="dashboard_tab">
                <Nav variant="pills">
                  <Nav.Item
                    onClick={() => {
                      setView("list");
                      searchParam.set("view", "list");
                      setSearchParam(searchParam);
                    }}
                  >
                    <Nav.Link className={view === "list" && "active"}>
                      List View
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item
                    onClick={() => {
                      setView("calender");
                      searchParam.set("view", "calender");
                      setSearchParam(searchParam);
                    }}
                  >
                    <Nav.Link className={view === "calender" && "active"}>
                      Calendar view
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item
                    onClick={() => {
                      setView("notAttempted");
                      searchParam.set("view", "notAttempted");
                      setSearchParam(searchParam);
                    }}
                  >
                    <Nav.Link className={view === "notAttempted" && "active"}>
                      Not attempted View
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  {/* list view section */}
                  {view === "list" && <ListViewTest />}

                  {/* calender view section */}
                  {view === "calender" && <CalenderViewTest />}

                  {/* not attempt view section */}
                  {view === "notAttempted" && <NotAttemptedView />}
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        )}
        {/* if test is not available in offering and addons */}
        {!course.isTestAvailable && (
          <TestNotFound title="Test series feature" />
        )}
      </div>
    </div>
  );
}

export default TestSeries;
