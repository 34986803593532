import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { GetSeoDetails } from "services/courses/courses";
import { errorResponse, failResponse } from "config/config";

const LocationTracker = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [metakeywords, setmetakeywords] = useState("");
  const [metatitle, setmetatitle] = useState("");
  const [metadescription, setmetadescription] = useState("");
  const [metacanonocaltag, setmetacanonocaltag] = useState("");

  useEffect(() => {
    setCurrentPath(location.pathname); // Update currentPath when location changes
  }, [location]);

  // Fetch SEO details on path change
  useEffect(() => {
    const fetchSeoDetails = async () => {
      try {
        const res = await GetSeoDetails({ path: currentPath });
        if (res.code === 200) {
          const { seodetails } = res.data;
          setmetatitle(seodetails.metatitle)
          setmetakeywords(seodetails.metakeywords)
          setmetadescription(seodetails.metadescription)
          setmetacanonocaltag(seodetails.metacanonocaltag)
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    fetchSeoDetails();
  }, [currentPath]); // Re-run the effect when currentPath changes

  return (
    <>

      <Helmet>
        <title>{metatitle || "Shubhra Ranjan"}</title>
        <meta name="description" content={metadescription || "Shubhra Ranjan IAS Study Pvt. Ltd. IAS Academy is the best IAS coaching Institute in Pune, India. UPSC IAS UPSC Study center Pune UPSC Study center Hyderabad/Bengaluru/Delhi/Lucknow/chandigarh/Jaipur/Pune/Ahmedabad IAS coaching classes, Best Classes for UPSC Foundation, MPSC Foundation, Classes for all UPSC Foundation, MPSC Foundation in Pune, offered by Aces IAS Academy Pune Maharashtra India."} />
        <meta name="keywords" content={metakeywords || "IAS academy, IAS coaching, best IAS coaching in India, IAS coaching classes, civil service coaching, upsc coaching, best coaching for IAS, top IAS coaching in Delhi, best upsc coaching in India, upsc classes, top IAS coaching in India, upsc coaching in Delhi, best upsc coaching in Delhi, IAS coaching centre in Delhi, psir online coaching, online IAS coaching, online IAS academy, upsc online coaching classes, civil service coaching, psir optional online classes"} />
        <link rel="canonical" href={metacanonocaltag || "https://www.shubhraranjan.com/"} />
      </Helmet>
      {/* {currentPath === "/freeresources/FRPrelimsSyllabus" && (
        <Helmet>
          <title>FR Prelims Syllabus</title>
          <meta name="description" content="This is the syllabus page description" />
          <meta name="keywords" content="free resources, syllabus, SEO" />
        </Helmet>
      )}

      {currentPath === "/freeresources/FRPrelimsPYQPrelims" && (
        <Helmet>
          <title>FR Prelims PYQ</title>
          <meta name="description" content="This is the Prelims page description" />
          <meta name="keywords" content="free resources, prelims, SEO" />
        </Helmet>
      )}

      {currentPath.includes("/course-detail") && (
        <Helmet>
          <title>Course Detail</title>
          <meta name="description" content="This is the Course Detail description" />
          <meta name="keywords" content="free resources, course, SEO" />
        </Helmet>
      )} */}
    </>
  );
};

export default LocationTracker;
